import React from "react";
import styled, { keyframes, css } from "styled-components";
import {
  WIDTH_SVG,
  HEIGHT_SVG,
  ROUTE_COLOR,
  SUB_ROUTE_COLOR,
} from "utils/constants";
import {
  useActionSwitchDurationBox,
  useActionNextRoute,
} from "state/actions/RoutingActions";

const dash = (stroke: string | number | undefined) => keyframes`
from {
    stroke-dashoffset: ${stroke || 0};
  }
  to {
    stroke-dashoffset: 0;
  }
`;

const AnimationLine = styled.polyline`
  animation: ${(props) => css`
    ${dash(props.strokeDasharray)} 2s ease
  `};
`;

interface IProps2 {
  path: string;
  id: number;
  onClickHandler: () => void;
  type: "main" | "secondary";
  distance?: number;
}

/**
 * Draw route on the svg.
 */
const DrawRoute: React.FC<IProps2> = (props) => {
  const { path, id, type, onClickHandler, distance } = props;
  return (
    <svg viewBox={`0 0 ${WIDTH_SVG} ${HEIGHT_SVG}`} key={id}>
      <g
        id={`route-${id}`}
        onClick={onClickHandler}
        onTouchEnd={onClickHandler}
      >
        <AnimationLine
          points={path}
          fill="none"
          stroke="black"
          strokeWidth="1"
          id="guest-path2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray={distance || "0.05, 2"}
        />
        <AnimationLine
          points={path}
          fill="none"
          stroke={type === "main" ? ROUTE_COLOR : SUB_ROUTE_COLOR}
          strokeWidth="0.8"
          id="guest-path"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray={distance || "0.05, 2"}
        />
      </g>
    </svg>
  );
};
interface IProps {
  route: IRoute;
  id: number;
  type: "main" | "secondary";
  dashed: boolean;
}
/**
 * Route component. Contains safe and unsafe routes.
 */
const Route: React.FC<IProps> = (props) => {
  const { route, id, type } = props;
  const switchDurationBox = useActionSwitchDurationBox();
  const nextRoute = useActionNextRoute();

  const createPath = (path: number[][]) => {
    return path.reduce(
      (acc: string, current: number[]) => `${acc} ${current[0]},${current[1]}`,
      "",
    );
  };

  const onClickHandler = () => {
    if (type === "main") {
      switchDurationBox();
    } else {
      nextRoute(id);
    }
  };

  if (!route || !route.path.length) return <g />;
  return (
    <g>
      <DrawRoute
        id={id}
        path={createPath(route.safe)}
        onClickHandler={() => onClickHandler()}
        distance={route.distance}
        type={type}
      />
      <DrawRoute
        id={id}
        path={createPath(route.unsafe)}
        onClickHandler={() => onClickHandler()}
        type={type}
      />
    </g>
  );
};

export default Route;
