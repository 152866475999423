import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import * as serviceWorker from "./serviceWorker";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.scss";
import "./translations/i18n";
import "polyfill";
import { name, version } from "../package.json";

console.log(name, version);

//Block browser zoom on mobile devices
document.addEventListener(
  "touchmove",
  function (event) {
    if (
      event.scale !== 1 &&
      (!event.target.closest(".scrollable") || event.touches.length > 1)
    ) {
      event.preventDefault();
    }
  },
  { passive: false },
);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
