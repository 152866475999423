import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from "@fortawesome/free-solid-svg-icons";
import { Card, ListGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { useSelection, useDatabase, useMode } from "state/StateManager";
import getBuilding from "utils/getBuilding";
import { SEARCH_MODE, ROUTE_MODE } from "utils/constants";
import { useActionAddRoutingEnd } from "state/actions/RoutingActions";
import { useActionChangeMode } from "state/actions/OtherActions";

const FloatCard = styled(Card)`
  position: absolute !important;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  margin: 1rem;
  text-align: center;
  z-index: 25;
`;

const FloatCardBody = styled(Card.Body)`
  padding: 0.5rem !important;
`;

const ScrollListGroup = styled(ListGroup)`
  line-height: 1rem;
  max-height: 25vh;
  margin-bottom: 1rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const ListGroupItemHighLight = styled(ListGroup.Item)`
  background-color: lightgray !important;
`;

const ListGroupItemTitle = styled(ListGroup.Item)`
  background-color: rgba(0, 0, 0, 0.03) !important;
  font-weight: bold;
`;

const FloatAwesomeIcon = styled(FontAwesomeIcon)`
  float: right;
  display: inline-block;
`;

const LinkText = styled.p`
  text-decoration: underline;
  display: inline;
  margin: 5px;
  color: #00677f;
  font-size: 0.8rem;

  &:hover {
    cursor: pointer;
    color: #e69123;
  }
`;

/**
 * Information box about selected building or location.
 */
const LocationDescription: React.FC = () => {
  const { i18n, t } = useTranslation();
  const selection = useSelection();
  const database = useDatabase();
  const mode = useMode();
  const addRoutingEnd = useActionAddRoutingEnd();
  const changeMode = useActionChangeMode();

  const [open, setOpen] = useState(true);
  const [buildingSelected, setBuildingSelected] = useState({} as ILocationData);
  const [locationsInside, setlocationsInside] = useState([] as ILocationData[]);
  const selectedItemRef = useRef<any>(null);

  useEffect(() => {
    setOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection.current]);

  useEffect(() => {
    if (selection.current) {
      const building = getBuilding(selection.current, database);
      if (building) {
        setBuildingSelected(building);
      }
    }
  }, [selection, database]);

  useEffect(() => {
    if (buildingSelected.has) {
      const contains = buildingSelected.has
        .reduce(
          (acc: ILocationData[], locationId: string) => {
            const locationObj = database[locationId];
            if (locationObj) return [...acc, locationObj];
            return acc;
          },
          [{} as ILocationData],
        )
        .sort((a: ILocationData, b: ILocationData) =>
          a.floor === b.floor
            ? a.name < b.name
              ? -1
              : 1
            : a.floor < b.floor
              ? -1
              : 1,
        );
      setlocationsInside(contains);
    }
  }, [buildingSelected, database]);

  useLayoutEffect(() => {
    if (selectedItemRef.current) {
      selectedItemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  });

  const renderListCategory = (category: string) => {
    const filterByCategory = locationsInside.filter(
      (location: ILocationData) => location.type === category,
    );
    if (!filterByCategory.length) return <div />;
    return (
      <>
        <ListGroupItemTitle>{t(category)}</ListGroupItemTitle>
        {filterByCategory.map((location: ILocationData) => {
          if (location.mapid === selection.current) {
            return (
              <ListGroupItemHighLight
                key={location.mapid}
                ref={selectedItemRef}
              >
                {i18n.language === "ja" && location.translation !== ""
                  ? location.translation
                  : location.name}
                {location.floor
                  ? ` (${location.floor}${i18n.language === "ja" ? "階" : "F"})`
                  : ""}
              </ListGroupItemHighLight>
            );
          }
          return (
            <ListGroup.Item key={location.mapid}>
              {i18n.language === "ja" && location.translation !== ""
                ? location.translation
                : location.name}
              {location.floor
                ? ` (${location.floor}${i18n.language === "ja" ? "階" : "F"})`
                : ""}
            </ListGroup.Item>
          );
        })}
      </>
    );
  };

  if (!selection.current) return <></>;
  if (!buildingSelected || mode !== SEARCH_MODE) return <></>;
  //fix bugs with android mobile, when soft keyboard appears.
  if (window.innerHeight < 300) return <></>;
  return (
    <FloatCard>
      <Card.Header as="h5" onClick={() => setOpen(!open)}>
        {i18n.language === "ja"
          ? buildingSelected.translation
          : buildingSelected.name}
        {buildingSelected.entrances && (
          <LinkText
            onClick={() => {
              addRoutingEnd(selection.current);
              changeMode(ROUTE_MODE);
              toast.info(t("choose-starting-point"));
            }}
          >
            {t("directions")}
          </LinkText>
        )}
        <FloatAwesomeIcon icon={open ? faAngleDoubleDown : faAngleDoubleUp} />
      </Card.Header>
      {locationsInside.length > 0 && open && (
        <FloatCardBody>
          <ScrollListGroup variant="flush" className="scrollable">
            {renderListCategory("DEPARTMENT")}
            {renderListCategory("MEETING_ROOM")}
            {renderListCategory("PRODUCTION")}
            {renderListCategory("OTHERS")}
          </ScrollListGroup>
        </FloatCardBody>
      )}
    </FloatCard>
  );
};

export default LocationDescription;
