import { useCallback } from "react";
import { useDispatchFunction } from "state/StateManager";

export const UPDATE_ZOOM: string = "updateZoom";
export const UPDATE_ROTATION: string = "updateRotation";
export const UPDATE_SCREEN_SIZE: string = "updateScreenSize";
export const RESET_ROTATION: string = "resetRotation";
export const ADD_ROTATION: string = "addRotation";

export const orientationActions = {
  UPDATE_SCREEN_SIZE,
  UPDATE_ROTATION,
  UPDATE_ZOOM,
  RESET_ROTATION,
  ADD_ROTATION,
};

/**
 * Action to update zoom information
 */
export function useActionUpdateZoom(): (zoom: number) => void {
  const dispatch = useDispatchFunction();
  return useCallback(
    (zoom: number) => {
      const action = {
        type: UPDATE_ZOOM,
        payload: zoom,
      };
      dispatch(action);
    },
    [dispatch],
  );
}

/**
 * Action to add rotation diff on the current map's rotation.
 */
export function useActionAddRotationDiff() {
  const dispatch = useDispatchFunction();
  return useCallback(
    (rotationDiff: number) => {
      if (rotationDiff !== 0) {
        const action = {
          type: ADD_ROTATION,
          payload: rotationDiff,
        };
        dispatch(action);
      }
    },
    [dispatch],
  );
}

/**
 * Action to set map's rotation.
 */
export function useActionUpdateRotation() {
  const dispatch = useDispatchFunction();
  return useCallback(
    (rotation: number) => {
      const action = {
        type: UPDATE_ROTATION,
        payload: rotation,
      };
      dispatch(action);
    },
    [dispatch],
  );
}

/**
 * Reset rotation to North.
 */
export function useActionResetOrientation() {
  const dispatch = useDispatchFunction();
  return useCallback(() => {
    const action = {
      type: RESET_ROTATION,
    };
    dispatch(action);
  }, [dispatch]);
}

/**
 * Action to update screen size.
 */
export function useActionUpdateScreenSize(): (
  width: number,
  height: number,
) => void {
  const dispatch = useDispatchFunction();
  return useCallback(
    (width: number, height: number) => {
      const action = {
        type: UPDATE_SCREEN_SIZE,
        payload: {
          width,
          height,
        },
      };
      // Ignores screen size change when the soft keyboard pops up
      if (
        !(
          document &&
          document.activeElement &&
          document.activeElement.getAttribute("type") === "search"
        )
      ) {
        dispatch(action);
      }
    },
    [dispatch],
  );
}
