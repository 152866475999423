import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Fuse from "fuse.js";
import throttle from "lodash/throttle";
import { Container, Row, Col, InputGroup, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDatabase, useRouting, useRefAction } from "state/StateManager";
import {
  useActionAddRoutingStart,
  useActionAddRoutingEnd,
  useActionCleanRoutingStart,
  useActionCleanRoutingEnd,
} from "state/actions/RoutingActions";

const fuseOptions = {
  shouldSort: true,
  includeMatches: true,
  threshold: 0.5,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["mapid", "name", "keywords"],
};

const FloatWrapper = styled.div`
  position: absolute;
  top: 0rem;
  left: 0rem;
  right: 0rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  z-index: 40;
`;

/**
 * Search bars to create a route between two building that have entrances.
 */
const SearchPath: React.FC = () => {
  const { t, i18n } = useTranslation();
  const database = useDatabase();
  const routing = useRouting();
  const refAction = useRefAction();
  const addRoutingStart = useActionAddRoutingStart();
  const addRoutingEnd = useActionAddRoutingEnd();
  const cleanRoutingStart = useActionCleanRoutingStart();
  const cleanRoutingEnd = useActionCleanRoutingEnd();
  const searchRouteStartRef = useRef<any>(null);
  const searchRouteEndRef = useRef<any>(null);
  const [options, setOptions] = useState<any | boolean>(false);
  const [optionsFilteredStart, setOptionsFilteredStart] = useState(
    [] as ILocationData[],
  );
  const [optionsFilteredEnd, setOptionsFilteredEnd] = useState(
    [] as ILocationData[],
  );
  const [searchTextStart, setSearchTextStart] = useState("");
  const [searchTextEnd, setSearchTextEnd] = useState("");

  //Filter with fuzzy search throttled
  const throttled = useRef(
    throttle(
      (
        searchText: string,
        options: any | boolean,
        setFunction: (e: ILocationData[]) => void,
      ) => {
        if (options) {
          const newOptions = options.search(searchText);
          setFunction(newOptions.map((e: any) => e.item));
        }
      },
      1000,
    ),
  );

  useEffect(
    () => throttled.current(searchTextStart, options, setOptionsFilteredStart),
    [searchTextStart, options],
  );
  useEffect(
    () => throttled.current(searchTextEnd, options, setOptionsFilteredEnd),
    [searchTextEnd, options],
  );

  useEffect(() => {
    const fuse = new Fuse(Object.values(database), fuseOptions);
    setOptions(fuse);
  }, [database]);

  useEffect(() => {
    if (searchRouteStartRef.current && refAction.blur !== null)
      searchRouteStartRef.current.blur();
    if (searchRouteEndRef.current && refAction.blur !== null)
      searchRouteEndRef.current.blur();
  }, [refAction.blur]);

  return (
    <FloatWrapper>
      <Container>
        <Row className="justify-content-center mb-2">
          <Col className="scrollable">
            <InputGroup>
              <InputGroup.Prepend>
                <Button
                  onClick={() => {
                    if (searchRouteStartRef.current) {
                      searchRouteStartRef.current.focus();
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                </Button>
              </InputGroup.Prepend>
              <Typeahead
                id="Search"
                inputProps={{ type: "search" }}
                ref={searchRouteStartRef}
                placeholder={t("choose-starting-point")}
                multiple={false}
                options={optionsFilteredStart}
                selected={
                  routing.start &&
                  database[routing.start]?.name &&
                  database[routing.start]?.translation
                    ? [database[routing.start]]
                    : []
                }
                filterBy={["mapid", "name", "keywords"]}
                labelKey={i18n.language === "ja" ? "translation" : "name"}
                minLength={1}
                selectHintOnEnter={true}
                onChange={(values) => {
                  if (values.length > 0) {
                    addRoutingStart(values[0].mapid);
                    searchRouteStartRef.current.blur();
                  }
                }}
                onInputChange={(e: string) => {
                  setSearchTextStart(e);
                  if (routing.start) {
                    cleanRoutingStart();
                  }
                }}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="scrollable">
            <InputGroup>
              <InputGroup.Prepend>
                <Button
                  variant="danger"
                  onClick={() => {
                    if (searchRouteEndRef.current) {
                      searchRouteEndRef.current.focus();
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                </Button>
              </InputGroup.Prepend>
              <Typeahead
                id="Search"
                inputProps={{ type: "search" }}
                ref={searchRouteEndRef}
                placeholder={t("choose-destination")}
                multiple={false}
                options={optionsFilteredEnd}
                selected={
                  routing.end &&
                  database[routing.end]?.name &&
                  database[routing.end]?.translation
                    ? [database[routing.end]]
                    : []
                }
                filterBy={["mapid", "name", "keywords"]}
                labelKey={i18n.language === "ja" ? "translation" : "name"}
                minLength={1}
                selectHintOnEnter={true}
                onChange={(values: ILocationData[]) => {
                  if (values.length > 0) {
                    addRoutingEnd(values[0].mapid);
                    searchRouteEndRef.current.blur();
                  }
                }}
                onInputChange={(e: string) => {
                  setSearchTextEnd(e);
                  if (routing.end) {
                    cleanRoutingEnd();
                  }
                }}
              />
            </InputGroup>
          </Col>
        </Row>
      </Container>
    </FloatWrapper>
  );
};

export default SearchPath;
