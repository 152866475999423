import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearchLocation,
  faRoute,
  faRedo,
  faDoorOpen,
  faExchangeAlt,
  faInfoCircle,
  faTimesCircle,
  faSignInAlt,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useMode, useSelection, useRouting } from "state/StateManager";
import FloatButton from "components/Menu/NavigationButtons/FloatingButton";
import { ROUTE_MODE, SEARCH_MODE } from "utils/constants";
import {
  useActionReset,
  useActionChangeMode,
} from "state/actions/OtherActions";
import {
  useActionNextRoute,
  useActionAddRoutingEnd,
  useActionCleanRoute,
} from "state/actions/RoutingActions";
import { useActionCleanSelection } from "state/actions/SelectionActions";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

interface IProps {
  showInfo: () => void;
}

const GET_API_ACCESS_MODE = gql`
  {
    mode
  }
`;

/**
 * Render all the navigation buttons.
 */
const NavigationButtons: React.FC<IProps> = ({ showInfo }) => {
  const { t } = useTranslation();
  const mode = useMode();
  const selection = useSelection();
  const routing = useRouting();
  const reset = useActionReset();
  const nextRoute = useActionNextRoute();
  const addRoutingEnd = useActionAddRoutingEnd();
  const changeMode = useActionChangeMode();
  const cleanRoute = useActionCleanRoute();
  const cleanSelection = useActionCleanSelection();
  const { loading, data } = useQuery(GET_API_ACCESS_MODE);

  const AuthButton = ({ blink }: { blink?: string }) => {
    const right = "0rem";
    if (!loading && data.mode === "public")
      return (
        <FloatButton
          description="login-description"
          top={top()}
          right={right}
          blink={blink}
          onClick={() => (window.location.href = "/auth/login")}
        >
          <FontAwesomeIcon icon={faSignInAlt} />
        </FloatButton>
      );
    if (!loading && data.mode === "internal")
      return (
        <FloatButton
          description="logout-description"
          top={top()}
          right={right}
          onClick={() => (window.location.href = "/auth/logout")}
        >
          <FontAwesomeIcon icon={faSignOutAlt} />
        </FloatButton>
      );
  };

  // determine button top placing in a programmatical way
  let currentTop = -1;
  const top = (reset?: boolean, resetTo?: number) => {
    if (reset) currentTop = -1;
    if (resetTo) currentTop = resetTo;
    return `${(currentTop += 4)}rem`;
  };

  if (mode === ROUTE_MODE) {
    return (
      <>
        <FloatButton
          description="info-description"
          top={top(true, 3)}
          right="0rem"
          onClick={() => showInfo()}
        >
          <FontAwesomeIcon icon={faInfoCircle} transform="grow-5" />
        </FloatButton>
        <FloatButton
          top={top()}
          right="0rem"
          description="reset-map"
          onClick={() => {
            if (routing.routes.length || routing.start || routing.end) {
              cleanRoute();
            } else {
              reset();
            }
          }}
        >
          <FontAwesomeIcon
            icon={
              routing.routes.length || routing.start || routing.end
                ? faTimesCircle
                : faRedo
            }
            transform="grow-5"
          />
        </FloatButton>
        <FloatButton
          description="back-search"
          top={top()}
          right="0rem"
          onClick={() => changeMode(SEARCH_MODE)}
        >
          <FontAwesomeIcon icon={faSearchLocation} transform="grow-5" />
        </FloatButton>
        {routing.routes.length > 1 && (
          <FloatButton
            description="change-entrance"
            top={top()}
            right="0rem"
            blink="true"
            onClick={() => nextRoute()}
          >
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon transform="grow-5 down-10" icon={faDoorOpen} />
              <FontAwesomeIcon transform="up-10" icon={faExchangeAlt} />
            </span>
          </FloatButton>
        )}
      </>
    );
  } else if (mode === SEARCH_MODE) {
    return (
      <>
        {AuthButton({ blink: "true" })}
        <FloatButton
          description="info-description"
          top={top()}
          right="0rem"
          onClick={() => showInfo()}
        >
          <FontAwesomeIcon icon={faInfoCircle} transform="grow-5" />
        </FloatButton>
        <FloatButton
          description="reset-map"
          top={top()}
          right="0rem"
          onClick={() => {
            if (selection.current) {
              cleanSelection();
            } else {
              reset();
            }
          }}
        >
          <FontAwesomeIcon
            icon={selection.current ? faTimesCircle : faRedo}
            transform="grow-5"
          />
        </FloatButton>
        {selection.hasEntrance && (
          <FloatButton
            description="create-route"
            top={top()}
            right="0rem"
            blink="true"
            onClick={() => {
              addRoutingEnd(selection.current);
              changeMode(ROUTE_MODE);
              toast.info(t("choose-starting-point"));
            }}
          >
            <FontAwesomeIcon icon={faRoute} transform="grow-5" />
          </FloatButton>
        )}
      </>
    );
  }
  return <div />;
};

export default NavigationButtons;
