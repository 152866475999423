import {
  useDatabase,
  useRouting,
  useLayoutMap,
  useOrientation,
  useDispatchFunction,
} from "state/StateManager";
import getBuilding from "utils/getBuilding";
import SVGtoScreenCoorinates from "utils/SVGtoScreenCoordinates";
import rotatePoint from "utils/rotatePoint";
import { useCallback } from "react";
import { HEIGHT_SVG, WIDTH_SVG } from "utils/constants";

export const ADD_ROUTING_END: string = "addRoutingEnd";
export const ADD_ROUTING_START: string = "addRoutingStart";
export const SEARCH_ROUTE: string = "searchRoute";
export const NEW_ROUTES: string = "newRoutes";
export const NEXT_ROUTE: string = "nextRoute";
export const CHANGE_ROUTE: string = "changeRoute";
export const CLEAN_ROUTE: string = "cleanRoute";
export const ERROR_NO_ENTRANCE: string = "no-entrance";
export const ERROR_SAME_BUILDING: string = "same-building";
export const DURATION_BOX_SWITCH: string = "DurationBoxSwitch";
export const CLEAN_ROUTE_END: string = "cleanRouteEnd";
export const CLEAN_ROUTE_START: string = "cleanRouteStart";

export const routingActions = {
  ADD_ROUTING_START,
  ADD_ROUTING_END,
  SEARCH_ROUTE,
  CHANGE_ROUTE,
  CLEAN_ROUTE,
  NEW_ROUTES,
  NEXT_ROUTE,
  ERROR_NO_ENTRANCE,
  ERROR_SAME_BUILDING,
  DURATION_BOX_SWITCH,
  CLEAN_ROUTE_END,
  CLEAN_ROUTE_START,
};

/**
 * Action to change the highlight route.
 */
export function useActionNextRoute() {
  const dispatch = useDispatchFunction();
  const routing = useRouting();
  return useCallback(
    (id?: number) => {
      let routeId = id;
      if (!routeId) {
        routeId = routing.indexMainRoute + 1;
        if (routeId === routing.routes.length) routeId = 0;
      }
      const action = {
        type: NEXT_ROUTE,
        payload: routeId,
      };
      dispatch(action);
    },
    [dispatch, routing],
  );
}

/**
 * Action to clear all the route information.
 */
export function useActionCleanRoute() {
  const dispatch = useDispatchFunction();
  return useCallback(() => {
    const action = {
      type: CLEAN_ROUTE,
    };
    dispatch(action);
  }, [dispatch]);
}

/**
 * Action to add new routes.
 */
export function useActionAddRoutes() {
  const dispatch = useDispatchFunction();
  const database = useDatabase();
  const routing = useRouting();
  const orientation = useOrientation();

  return useCallback(
    (routes: IRoute[], indexMainRoute: number) => {
      let action: IAction = {
        type: NEW_ROUTES,
        payload: {
          indexMainRoute,
          routes: routes,
        },
      };

      // Center view on the route created
      if (routes.length && database[routing.start] && database[routing.end]) {
        const buildingStart = getBuilding(routing.start, database);
        const buildingEnd = getBuilding(routing.end, database);
        if (buildingStart && buildingEnd) {
          const positionStart = buildingStart.position;
          const positionEnd = buildingEnd.position;
          if (positionStart && positionEnd) {
            const middleX = (positionStart.x + positionEnd.x) / 2;
            const middleY = (positionStart.y + positionEnd.y) / 2;
            const { x, y } = SVGtoScreenCoorinates(
              middleX,
              middleY,
              orientation.width,
              orientation.height,
            );
            const center = rotatePoint(
              [x, y],
              [orientation.width / 2, orientation.height / 2],
              orientation.rotation,
            );
            const diffX = Math.abs(positionStart.x - positionEnd.x);
            const diffY = Math.abs(positionStart.y - positionEnd.y);
            const scaleZoom =
              diffY / HEIGHT_SVG > diffX / WIDTH_SVG
                ? diffY / HEIGHT_SVG
                : diffX / WIDTH_SVG;
            const scaleSmooth = 0.7 / scaleZoom < 1 ? 1 : 0.7 / scaleZoom; //Bottom limit
            const scaleSmooth2 = scaleSmooth > 2.5 ? 2.5 : scaleSmooth; //Upper limit

            action = {
              ...action,
              payload: {
                ...action.payload,
                refAction: {
                  x: center.x,
                  y: center.y,
                  zoom: scaleSmooth2,
                },
              },
            };
          }
        }
      }
      dispatch(action);
    },
    [dispatch, database, orientation, routing],
  );
}

/**
 * Action to search for a route.
 */
export function useActionSearchRoute() {
  const dispatch = useDispatchFunction();
  const database = useDatabase();
  const map = useLayoutMap();

  return useCallback(
    (startLocation: string, endLocation: string) => {
      const startBuilding = getBuilding(startLocation, database);
      const endBuilding = getBuilding(endLocation, database);

      if (startBuilding && endBuilding) {
        if (startBuilding.mapid !== endBuilding.mapid) {
          if (startBuilding.entrances && endBuilding.entrances) {
            dispatch({
              type: SEARCH_ROUTE,
              payload: {
                start: startLocation,
                end: endLocation,
              },
            });
            return {
              type: "CALCULATE_PATH",
              payload: {
                map,
                startBuilding,
                endBuilding,
              },
            };
          } else {
            dispatch({
              type: ERROR_NO_ENTRANCE,
              payload: {
                start: startLocation,
                end: endLocation,
              },
            });
          }
        } else {
          dispatch({
            type: ERROR_SAME_BUILDING,
            payload: {
              start: startLocation,
              end: endLocation,
            },
          });
        }
      }
      return false;
    },
    [dispatch, database, map],
  );
}

/**
 * Action to add end location to the route.
 */
export function useActionAddRoutingEnd() {
  const dispatch = useDispatchFunction();
  return useCallback(
    (location: string) => {
      const action = {
        type: ADD_ROUTING_END,
        payload: location,
      };
      dispatch(action);
    },
    [dispatch],
  );
}
/**
 * Action to add start location to the route.
 */
export function useActionAddRoutingStart() {
  const dispatch = useDispatchFunction();
  return useCallback(
    (location: string) => {
      const action = {
        type: ADD_ROUTING_START,
        payload: location,
      };
      dispatch(action);
    },
    [dispatch],
  );
}

/**
 * Action to change Duration Route Box's visibility.
 */
export function useActionSwitchDurationBox() {
  const dispatch = useDispatchFunction();
  return useCallback(() => {
    const action = {
      type: DURATION_BOX_SWITCH,
    };
    dispatch(action);
  }, [dispatch]);
}

/**
 * Action to delete routing start location
 */
export function useActionCleanRoutingStart(): () => void {
  const dispatch = useDispatchFunction();
  return useCallback(() => {
    const action = {
      type: CLEAN_ROUTE_START,
    };
    dispatch(action);
  }, [dispatch]);
}

/**
 * Action to delete routing end location
 */
export function useActionCleanRoutingEnd(): () => void {
  const dispatch = useDispatchFunction();
  return useCallback(() => {
    const action = {
      type: CLEAN_ROUTE_END,
    };
    dispatch(action);
  }, [dispatch]);
}
