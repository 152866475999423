import React from "react";
import { SvgLoader } from "react-svgmt";
import { HEIGHT_SVG, WIDTH_SVG } from "utils/constants";

interface IProps {
  mapSVG: string;
  children: React.ReactNode;
}
/**
 * Load SVG information to be saved on the app's state.
 */
const LoadSVG: React.FC<IProps> = ({ mapSVG, children }) => {
  return (
    <SvgLoader
      width={WIDTH_SVG}
      height={HEIGHT_SVG}
      path={mapSVG}
      style={{ position: "absolute", top: "0", left: "0", opacity: 0 }}
    >
      {children}
    </SvgLoader>
  );
};

export default LoadSVG;
