import React from "react";
import { SvgLoader } from "react-svgmt";
import { SvgProxy } from "react-svgmt";
import { useActionSelectLocation } from "state/actions/SelectionActions";

interface IProps {
  svg: string;
  elements: string[];
  invisible: boolean;
  selectionDisable: boolean;
}
/**
 * Make SVG groups selectable, by attaching onTouch and onClick events.
 * Only selectable elements will be kept on the SVG, the rest will be removed for
 * performance improvement.
 */
const SelectableElements: React.FC<IProps> = (props) => {
  const { svg, elements, invisible, selectionDisable } = props;
  const selectLocation = useActionSelectLocation();

  const selectorElements: string = elements.reduce(
    (acc: string, element: string, index: number) => {
      if (index === 0) return `${acc} #${element}`;
      return `${acc}, #${element}`;
    },
    "",
  );

  const selectorNotElements: string = elements.reduce(
    (acc: string, element: string) => `${acc}:not(#${element})`,
    "svg > g",
  );

  return (
    <SvgLoader path={svg} style={{ opacity: invisible ? 0 : 1 }}>
      <SvgProxy //Make elements selectable
        fill="transparent"
        selector={selectorElements}
        onTouchEnd={(e: any) => {
          if (!selectionDisable) {
            selectLocation(e.target.parentElement.id);
          }
        }}
        onClick={(e: any) => {
          if (!selectionDisable) {
            selectLocation(e.target.parentElement.id);
          }
        }}
      />

      <SvgProxy //Remove everything that is not selectable
        selector={selectorNotElements}
        onElementSelected={(e: SVGElement[]) => {
          Array.prototype.forEach.call(e, function (node) {
            node.parentNode.removeChild(node);
          });
        }}
      />
    </SvgLoader>
  );
};

export default SelectableElements;
