import { useCallback } from "react";
import {
  useDatabase,
  useDispatchFunction,
  useSelection,
  useMode,
  useRouting,
  useOrientation,
} from "state/StateManager";
import getBuilding from "utils/getBuilding";
import { ROUTE_MODE, SEARCH_MODE } from "utils/constants";
import {
  useActionAddRoutingStart,
  useActionAddRoutingEnd,
} from "state/actions/RoutingActions";
import SVGtoScreenCoorinates from "utils/SVGtoScreenCoordinates";

export const SELECT_LOCATION: string = "selectLocation";
export const DESELECT_LOCATION: string = "deselectLocation";
export const CREATE_SEARCH: string = "createSearch";
export const CLEAN_SELECTION: string = "cleanSelection";

export const selectionActions = {
  SELECT_LOCATION,
  DESELECT_LOCATION,
  CREATE_SEARCH,
  CLEAN_SELECTION,
};
/**
 * Action to clean selected location.
 */
export function useActionCleanSelection(): () => void {
  const dispatch = useDispatchFunction();
  return useCallback(() => {
    const action = {
      type: CLEAN_SELECTION,
    };
    dispatch(action);
  }, [dispatch]);
}

/**
 * Action to select/deselect a location.
 */
export function useActionSelectLocation(): (locationName: string) => void {
  const dispatch = useDispatchFunction();
  const database = useDatabase();
  const selection = useSelection();
  const mode = useMode();
  const routing = useRouting();
  const addRoutingStart = useActionAddRoutingStart();
  const addRoutingEnd = useActionAddRoutingEnd();

  return useCallback(
    (locationName: string) => {
      let action: IAction = {} as IAction;
      if (mode === ROUTE_MODE) {
        if (!routing.end) addRoutingEnd(locationName);
        else if (!routing.start) addRoutingStart(locationName);
      } else if (mode === SEARCH_MODE) {
        //Search Mode
        //Same location => Deselect
        if (selection.current === locationName) {
          action = {
            type: DESELECT_LOCATION,
          };
        } else {
          const building = getBuilding(locationName, database);
          action = {
            type: SELECT_LOCATION,
            payload: {
              locationName,
              hasEntrance: building ? !!building.entrances : false,
            },
          };
        }
      }
      dispatch(action);
    },
    [
      dispatch,
      database,
      selection,
      mode,
      routing,
      addRoutingEnd,
      addRoutingStart,
    ],
  );
}

/**
 * Action to search a location in the map, then zoom and pan to it.
 */
export function useActionCreateSearch(): (locationName: string) => void {
  const dispatch = useDispatchFunction();
  const database = useDatabase();
  const orientation = useOrientation();

  return useCallback(
    (locationName: string) => {
      const building = getBuilding(locationName, database);
      let action: IAction = {
        type: CREATE_SEARCH,
        payload: {
          locationName,
          hasEntrance: building ? !!building.entrances : false,
        },
      };
      // Pan to the location
      if (building && building.position) {
        const { position } = building;
        action = {
          ...action,
          payload: {
            ...action.payload,
            refAction: {
              ...SVGtoScreenCoorinates(
                position.x,
                position.y,
                orientation.width,
                orientation.height,
              ),
              zoom: 2,
            },
          },
        };
      }
      dispatch(action);
    },
    [dispatch, database, orientation.width, orientation.height],
  );
}
