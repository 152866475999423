import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import useLongPress from "components/Menu/NavigationButtons/useLongPress";
import TooltipOverlay from "components/TooltipOverlay";

const fadeIn = keyframes`
0%{
  opacity: 0;
  transform: scale(0.3) translate3d(0,0,0);
}
50%{
  opacity: 0.9;
  transform: scale(1.1);
}
80%{
  opacity: 1;
  transform: scale(0.89);
}
100%{
  opacity: 1;
  transform: scale(1) translate3d(0,0,0);
}
`;

const glowing = keyframes`
  0% {
    background-color: #27889f;
    border-color: #27889f;
    -moz-box-shadow: 0 0 3px #27889f;
  }
  50% {
    background-color: #5facbf;
    border-color: #5facbf;
    -moz-box-shadow: 0 0 40px #5facbf;
  }
  100% {
    background-color: #00677f;
    border-color: #00677f;
    -moz-box-shadow: 0 0 3px #00677f;
  }
`;

const FloatButton = styled(Button)`
  position: absolute;
  width: ${(props) => props.width || "3rem"};
  height: ${(props) => props.height || "3rem"};
  top: ${(props) => props.top || "auto"};
  right: ${(props) => props.right || "auto"};
  bottom: ${(props) => props.bottom || "auto"};
  left: ${(props) => props.left || "auto"};
  margin: 1rem;
  text-align: center;
  z-index: 30;
  font-size: 1rem;
  padding: 0.1rem !important;
  animation: ${fadeIn} normal 1s linear
    ${(props) =>
      props.blink === "true" ? css`,${glowing} 1500ms infinite` : ""};
`;
interface IProps {
  description: string;
  width?: string;
  height?: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  blink?: string;
  children: React.ReactNode;
  onClick: () => void;
}
/**
 * Float button.
 */
const FloatingButton: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const [clicked, setClicked] = useState<boolean>(false);
  const backspaceLongPress = useLongPress(
    () => toast.info(t(props.description)),
    500,
  );

  return (
    <TooltipOverlay placement="left" description={props.description}>
      <FloatButton
        {...props}
        {...backspaceLongPress}
        blink={((props.blink && !clicked) || false).toString()}
        onClick={() => {
          setClicked(true);
          props.onClick();
        }}
      >
        {props.children}
      </FloatButton>
    </TooltipOverlay>
  );
};

export default FloatingButton;
