import React from "react";
import { useMode } from "state/StateManager";
import { ROUTE_MODE, SEARCH_MODE } from "utils/constants";
import SearchBarLocation from "components/Menu/SearchBar/SearchBarLocation";
import SearchBarPath from "components/Menu/SearchBar/SearchBarRoute";

/**
 * Render search inputs.
 */
const Search: React.FC = () => {
  const mode = useMode();
  if (mode === ROUTE_MODE) {
    return <SearchBarPath />;
  } else if (mode === SEARCH_MODE) {
    return <SearchBarLocation />;
  }
  return <div />;
};

export default Search;
