import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import SafetyCardFront from "assets/safety-card-front.svg";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const SafetyCard = styled.img`
  width: 100%;
`;

/**
 * Disclaimer text
 */
export default function DisclaimerText() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <h5>{t("privacy-policy")}</h5>
      <p>
        <a
          href="https://reception.mitsubishi-fuso.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("privacy-policy")}
        </a>
      </p>
      <h5>{t("cookie-policy")}</h5>
      <p>
        <a
          href="https://reception.mitsubishi-fuso.com/cookies"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("cookie-policy")}
        </a>
      </p>
      <h5>{t("disclaimer")}</h5>
      {i18n.language === "ja" ? <DisclaimerTextJa /> : <DisclaimerTextEn />}
      <SafetyCard src={SafetyCardFront} />
    </div>
  );
}

function DisclaimerTextEn() {
  return (
    <div>
      <p>
        This map is provided as a best-effort service by ITC/AC in collaboration
        with HRT/AM. As such, map, routing and other data may be incomplete or
        faulty. Please take the necessary precautions to stay safe and watch
        your surroundings at all times. Use on your own responsibility.
      </p>
      <p>
        This application needs to know your location for some features to work
        properly. To determine your location some technologies may be used, such
        as IP address, GPS, and Wi-Fi. Your location is not shared or storaged
        with any third parties. You can disable it at any time by clicking on
        the icon <FontAwesomeIcon color="gray" icon={faMapMarkedAlt} />.
      </p>
    </div>
  );
}

function DisclaimerTextJa() {
  return (
    <div>
      <p>
        このマップは、ITC/AC と HRT/AM
        のコラボレーションで開発されています。万全を期しておりますが、マップ、
        経路表示、およびその他のデータが不完全または欠陥がある可能性があります。
        周囲に注意し、安全を確保し、自己責任のもと ご利用ください。
      </p>
      <p>
        このアプリケーションは、いくつかの機能にユーザーの位置情報が必要です。
        現在地を特定するために、IPアドレス、GPS、Wi-Fiなどの一部を使用します。
        ユーザーの位置情報は保存されず、第三者と共有もされません。
        <FontAwesomeIcon color="gray" icon={faMapMarkedAlt} />
        アイコンをクリックすると、いつでも無効にできます。
      </p>
    </div>
  );
}
