import React from "react";
import { useTranslation } from "react-i18next";
import { useRouting, useOrientation } from "state/StateManager";
import { useActionSwitchDurationBox } from "state/actions/RoutingActions";
import {
  HEIGHT_SVG,
  WIDTH_SVG,
  WALKING_SPEED,
  SVGMETERS,
  DURATION_BOX_OFFSET_X,
  DURATION_BOX_OFFSET_Y,
} from "utils/constants";
import secondsToString from "utils/secondsToString";
import rotatePoint from "utils/rotatePoint";

/**
 * Show how long the path takes.
 */
const DurationBox: React.FC = () => {
  const { i18n } = useTranslation();
  const routing = useRouting();
  const orientation = useOrientation();
  const switchDurationBox = useActionSwitchDurationBox();

  if (!routing.routes.length || !routing.showDuration) return <g />;
  const scale: number = (1 / orientation.zoom) * 4;
  const seconds =
    (routing.routes[routing.indexMainRoute].distance * SVGMETERS) /
    WALKING_SPEED;
  const duration = secondsToString(seconds, i18n.language);
  const position =
    routing.routes[routing.indexMainRoute].path[
      Math.floor(routing.routes[routing.indexMainRoute].path.length / 2)
    ];
  const positionRotated = rotatePoint(
    [position[0], position[1]],
    [0, 0],
    orientation.rotation,
  );
  const OFFSET_X = DURATION_BOX_OFFSET_X * scale;
  const OFFSET_Y = DURATION_BOX_OFFSET_Y * scale;

  const toogleVisibility = () => {
    switchDurationBox();
  };

  if (seconds > 59) {
    return (
      <svg
        viewBox={`0 0 ${WIDTH_SVG} ${HEIGHT_SVG}`}
        style={{ overflow: "visible" }}
      >
        <g
          id="timeEstimatedBox"
          transform={`rotate(${-orientation.rotation})`}
          onClick={toogleVisibility}
          onTouchEnd={toogleVisibility}
        >
          <g transform={`translate(${OFFSET_X} ${OFFSET_Y})`} fill="red">
            <g
              transform={`translate(${positionRotated.x} ${positionRotated.y}) scale(${scale})`}
            >
              <path
                id="timeBox"
                d="m4.0483 14.619 0.013108-3.9879 9.9364-0.0071-0.0139 4.0074-6.0552-0.0258-1.0696 0.99132-1.0781-0.98046-1.7328 2e-3z"
                fill="#fff"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="3.9941"
                strokeWidth=".1"
              />
              <text
                id="text2325"
                x="6.7230072"
                y="13.142777"
                fill="#000000"
                fontFamily="sans-serif"
                fontSize="1.4994px"
                fontWeight="normal"
                letterSpacing=".0056229px"
                strokeWidth=".28114"
                wordSpacing="0px"
              >
                <tspan
                  id="tspan2323"
                  x="6.7230072"
                  y="13.142777"
                  strokeWidth=".28114"
                >
                  {duration}
                </tspan>
              </text>
              <g
                id="g10"
                transform="matrix(.0076121 0 0 .0076121 3.0954 10.166)"
              >
                <g id="layer1" fill="#231f20">
                  <path
                    id="path5430"
                    d="m321.07 193.08c14.825 1.2048 27.711-9.8482 28.916-24.673 1.1524-14.825-9.9005-27.711-24.725-28.968-14.772-1.1001-27.763 9.9529-28.864 24.778-1.2572 14.72 9.7958 27.711 24.673 28.864"
                  />
                  <path
                    id="path5432"
                    d="m298.23 204.13c5.3431-3.7193 11.786-6.1813 18.911-5.5527 9.2195 0.73337 16.815 6.4432 21.425 13.41l27.082 53.798 37.035 25.511c3.1954 2.4097 5.1336 6.3385 4.7145 10.634-0.52383 6.6527-6.3384 11.629-12.991 11.053-2.043-0.10477-3.6669-0.94293-5.5527-1.7811l-40.388-27.816c-1.2048-0.99527-2.2525-2.2525-3.0906-3.5621l-10.11-20.168-12.153 53.641 47.669 56.365c1.1 1.7287 1.8334 3.7716 2.2525 5.8146l12.886 68.047c-0.10477 1.5191 0.10477 2.462 0 3.6669-0.73337 10.162-9.5339 17.496-19.539 16.763-8.2766-0.681-14.458-6.8099-16.396-14.406l-12.153-63.751-38.764-42.431-9.01 41.331c-0.3143 1.9382-2.8811 6.0241-3.6145 7.648l-37.245 62.756c-3.6669 5.7098-9.9006 9.3767-16.972 8.8005-10.11-0.73337-17.601-9.5338-16.763-19.592 0.20955-2.8287 1.4144-5.8146 2.462-7.8576l34.573-57.989 28.864-127.82-18.858 15.244-10.005 45.522c-1.3096 5.867-6.6527 10.634-12.886 10.162-6.7575-0.52383-11.682-6.3384-11.158-13.096 0-0.52383 0.10476-1.0477 0.20953-1.6763l11.839-53.065c0.73338-2.2525 1.9906-4.2955 3.7193-5.867z"
                  />
                </g>
              </g>
              <g id="closeX" transform="translate(13.3, 11.8) scale(0.08)">
                <text
                  textAnchor="middle"
                  fill="black"
                  stroke="black"
                  strokeWidth=".4"
                >
                  &#xf00d;
                </text>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
  return (
    <svg viewBox={`0 0 ${WIDTH_SVG} ${HEIGHT_SVG}`}>
      <g
        id="timeEstimatedBox"
        transform={`rotate(${-orientation.rotation})`}
        onClick={toogleVisibility}
        onTouchEnd={toogleVisibility}
      >
        <g transform={`translate(${OFFSET_X} ${OFFSET_Y})`}>
          <g
            transform={`translate(${positionRotated.x} ${positionRotated.y}) scale(${scale})`}
          >
            <path
              id="timeBox"
              d="m4.0483 14.619 0.013108-3.9879 7.7211-0.02021-0.0139 4.0074-3.8399-0.01269-1.0696 0.99132-1.0781-0.98046-1.7328 2e-3z"
              fill="#fff"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="3.9941"
              strokeWidth=".1"
            />
            <text
              id="text2325"
              x="6.5320139"
              y="13.04726"
              fill="#000000"
              fontFamily="sans-serif"
              fontSize="1.7476px"
              fontWeight="normal"
              letterSpacing=".0065536px"
              strokeWidth=".32768"
              wordSpacing="0px"
            >
              <tspan
                id="tspan2323"
                x="6.5320139"
                y="13.04726"
                strokeWidth=".32768"
              >
                {duration}
              </tspan>
            </text>
            <g id="g10" transform="matrix(.0076121 0 0 .0076121 2.8201 10.114)">
              <g id="layer1" fill="#231f20">
                <path
                  id="path5430"
                  d="m321.07 193.08c14.825 1.2048 27.711-9.8482 28.916-24.673 1.1524-14.825-9.9005-27.711-24.725-28.968-14.772-1.1001-27.763 9.9529-28.864 24.778-1.2572 14.72 9.7958 27.711 24.673 28.864"
                />
                <path
                  id="path5432"
                  d="m298.23 204.13c5.3431-3.7193 11.786-6.1813 18.911-5.5527 9.2195 0.73337 16.815 6.4432 21.425 13.41l27.082 53.798 37.035 25.511c3.1954 2.4097 5.1336 6.3385 4.7145 10.634-0.52383 6.6527-6.3384 11.629-12.991 11.053-2.043-0.10477-3.6669-0.94293-5.5527-1.7811l-40.388-27.816c-1.2048-0.99527-2.2525-2.2525-3.0906-3.5621l-10.11-20.168-12.153 53.641 47.669 56.365c1.1 1.7287 1.8334 3.7716 2.2525 5.8146l12.886 68.047c-0.10477 1.5191 0.10477 2.462 0 3.6669-0.73337 10.162-9.5339 17.496-19.539 16.763-8.2766-0.681-14.458-6.8099-16.396-14.406l-12.153-63.751-38.764-42.431-9.01 41.331c-0.3143 1.9382-2.8811 6.0241-3.6145 7.648l-37.245 62.756c-3.6669 5.7098-9.9006 9.3767-16.972 8.8005-10.11-0.73337-17.601-9.5338-16.763-19.592 0.20955-2.8287 1.4144-5.8146 2.462-7.8576l34.573-57.989 28.864-127.82-18.858 15.244-10.005 45.522c-1.3096 5.867-6.6527 10.634-12.886 10.162-6.7575-0.52383-11.682-6.3384-11.158-13.096 0-0.52383 0.10476-1.0477 0.20953-1.6763l11.839-53.065c0.73338-2.2525 1.9906-4.2955 3.7193-5.867z"
                />
              </g>
            </g>
            <g id="closeX" transform="translate(11.1, 11.8) scale(0.08)">
              <text
                textAnchor="middle"
                fill="black"
                stroke="black"
                strokeWidth=".4"
              >
                &#xf00d;
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DurationBox;
