/**
 * Get rotation factor from touch gesture movement.
 */
export function getRotationFactor(
  touch1: React.TouchList,
  touch2: React.TouchList,
): number {
  if (touch1.length > 1 && touch2.length > 1) {
    const x1 = touch1[0].clientX;
    const y1 = touch1[0].clientY;
    const x2 = touch1[1].clientX;
    const y2 = touch1[1].clientY;

    const px1 = touch2[0].clientX;
    const py1 = touch2[0].clientY;
    const px2 = touch2[1].clientX;
    const py2 = touch2[1].clientY;

    const angle1 = Math.atan2(y1 - y2, x1 - x2);
    const angle2 = Math.atan2(py1 - py2, px1 - px2);
    const rotateFactor = angle1 - angle2;

    return (rotateFactor * 180) / Math.PI;
  }
  return 0;
}
