import React, { useState } from "react";
import styled from "styled-components";
import { Modal, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DisclaimerText from "components/Menu/Disclaimer/DisclaimerText";

const ScrollBody = styled(Modal.Body)`
  max-height: 50vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

interface IProps {
  onHide: () => void;
  show: boolean;
}
/**
 * Disclaimer window.
 */
const Disclaimer: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<boolean>(
    !!localStorage.getItem("disclaimerMap"),
  );

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">{t("tos")}</Modal.Title>
      </Modal.Header>
      <ScrollBody className="scrollable">
        <DisclaimerText />
      </ScrollBody>
      <Modal.Footer className="justify-content-between">
        <Form.Group>
          <Form.Check
            type="checkbox"
            label={t("i-agree")}
            checked={checked}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.checked) {
                localStorage.setItem("disclaimerMap", "true");
                setChecked(true);
              } else {
                localStorage.removeItem("disclaimerMap");
                setChecked(false);
              }
            }}
          />
        </Form.Group>
        <Button disabled={!checked} onClick={props.onHide}>
          {t("do-not-show-again")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Disclaimer;
