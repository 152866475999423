import React from "react";
import {
  useDatabase,
  useMode,
  useSelection,
  useRouting,
} from "state/StateManager";
import Pin from "components/Map/PinLocations/Pin";
import getBuilding from "utils/getBuilding";
import {
  SEARCH_MODE,
  ROUTE_MODE,
  BLUE_COLOR,
  RED_COLOR,
} from "utils/constants";

/**
 * All pins on the map.
 */
const PinLocations: React.FC = () => {
  const mode = useMode();
  const selection = useSelection();
  const routing = useRouting();
  if (mode === SEARCH_MODE) {
    if (selection.current) {
      return <PinLocation location={selection.current} color={RED_COLOR} />;
    }
    return <div />;
  } else if (mode === ROUTE_MODE) {
    return (
      <>
        {routing.start && (
          <PinLocation location={routing.start} color={BLUE_COLOR} />
        )}
        {routing.end && (
          <PinLocation location={routing.end} color={RED_COLOR} />
        )}
      </>
    );
  }
  return <div />;
};

interface IProps {
  location: string;
  color: any;
}
/**
 * Pin a location on the map.
 */
const PinLocation: React.FC<IProps> = (props) => {
  const { location, color } = props;
  const database = useDatabase();
  const building = getBuilding(location, database);
  if (!building) return <g />;
  return (
    <Pin
      key={`pin-${building.name}`}
      color={color}
      x={building.position.x}
      y={building.position.y}
    />
  );
};

export default PinLocations;
