import React from "react";
import styled, { keyframes } from "styled-components";
import { ReactComponent as ReactLogo } from "assets/fuso.svg";

const pulse = keyframes`
0% {
  opacity: 0;
}
50% {
  opacity: 0.5;
}
100% {
  opacity: 1;
}
`;

const StyledSVG = styled(ReactLogo)`
  .logo {
    animation: ${pulse} infinite 3s linear;
  }
  display: block;
  margin: auto;
  height: 50%;
  width: 50%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
/**
 * Fuso spinner.
 */
const FusoSpinner: React.FC = () => {
  return <StyledSVG />;
};

export default FusoSpinner;
