import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { isBrowser } from "react-device-detect";

interface Props {
  description: string;
  children: React.ReactNode;
  placement?:
    | "auto-start"
    | "auto"
    | "auto-end"
    | "top-start"
    | "top"
    | "top-end"
    | "right-start"
    | "right"
    | "right-end"
    | "bottom-end"
    | "bottom"
    | "bottom-start"
    | "left-end"
    | "left"
    | "left-start";
}

export default function TooltipOverlay(props: Props) {
  const { t } = useTranslation();
  return (
    <OverlayTrigger
      placement={props.placement || "auto"}
      trigger="hover"
      overlay={
        isBrowser ? (
          <Tooltip id={`tooltip-${props.description}`}>
            {t(props.description)}
          </Tooltip>
        ) : (
          <div></div>
        )
      }
    >
      {props.children}
    </OverlayTrigger>
  );
}
