// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from "worker-loader!worker/Worker.js";
import React, { useEffect } from "react";
import { parseSVG, makeAbsolute } from "svg-path-parser";
import { SvgProxy } from "react-svgmt";
import { WIDTH_SVG, HEIGHT_SVG } from "utils/constants";
import { useActionAddLayoutMap } from "state/actions/LayoutMapActions";

const worker = new Worker();

interface WorkerData {
  data: {
    type: string;
    payload: any;
  };
}

interface IProps {
  onFinish: () => void;
}
/**
 * Abstract svg safe path group to a binary matrix and save on application's state.
 */
const LoadRoutes: React.FC<IProps> = ({ onFinish }) => {
  const addLayoutMap = useActionAddLayoutMap();

  useEffect(() => {
    const listener = ({ data: { type, payload } }: WorkerData) => {
      console.log("Routes: Complete.");
      if (type === "CALCULATE_GRID_SUCCESS") {
        addLayoutMap(payload);
        onFinish();
      }
    };
    worker.addEventListener("message", listener);
    return () => worker.removeEventListener("message", listener);
  }, [onFinish, addLayoutMap]);

  const updateMapInfo = (allPathsCoordinates: ISVGPath[]) => {
    console.log("Routes: Loading...");
    worker.postMessage({
      type: "CALCULATE_GRID",
      payload: {
        allPathsCoordinates,
        width: WIDTH_SVG,
        height: HEIGHT_SVG,
      },
    });
  };

  return (
    <SvgProxy
      selector="*[id^='routeSafe'], *[id^='routeUnsafe']"
      onElementSelected={(pathTest: Element[]) => {
        // Map walkable paths
        const SVGpaths = Array.from(pathTest);
        const allPathsCoordinates: ISVGPath[] = SVGpaths.map(
          (path: Element) => {
            const id: string = path.getAttribute("id") || "";
            const instructions: string = path.getAttribute("d") || "";
            const commands = parseSVG(instructions);
            makeAbsolute(commands);
            const pathNodesCoordinates = commands.map((e: any) => [
              parseFloat(e.x),
              parseFloat(e.y),
            ]);
            return {
              path: pathNodesCoordinates,
              safe: !id.includes("routeUnsafe"),
            };
          },
        );
        updateMapInfo(allPathsCoordinates);
      }}
    />
  );
};

export default LoadRoutes;
