import React from "react";
import { SvgProxy } from "react-svgmt";
import { useActionAddOnDatabase } from "state/actions/OtherActions";

interface IProps {
  groupName: string;
  onFinish: () => void;
}
/**
 * Load position of all elements inside a group(Buildings, Locations) into the database.
 */
const LoadPositions: React.FC<IProps> = ({ groupName, onFinish }) => {
  const addOnDatabase = useActionAddOnDatabase();

  const getPositionOnSVG = (element: Element) => {
    const elementPoint = element.getBoundingClientRect();
    const centerX = elementPoint.left + elementPoint.width / 2;
    const centerY = elementPoint.top + elementPoint.height / 2;
    return { x: centerX, y: centerY };
  };

  return (
    <SvgProxy
      selector={`#${groupName}`}
      onElementSelected={(e: Element) => {
        if (e.children) {
          const locations = Array.from(e.children);
          const newDatabase = locations.reduce(
            (acc: IDatabase, location: Element) => {
              return {
                ...acc,
                [location.id]: {
                  ...({} as ILocationData),
                  position: getPositionOnSVG(location),
                },
              };
            },
            {} as IDatabase,
          );
          addOnDatabase(newDatabase);
          onFinish();
        }
      }}
    />
  );
};

export default LoadPositions;
