/**
 * Transform seconds in a 00m 00s string format.
 */
export default function secondsToString(time: number, lng: string) {
  const seconds = Math.round(time);
  if (lng === "ja") {
    if (seconds >= 60) {
      return `${Math.floor(seconds / 60)}分 ${Math.round(seconds % 60)}秒`;
    }
    return `${Math.round(seconds % 60)}秒`;
  }
  if (seconds >= 60) {
    return `${Math.floor(seconds / 60)}m ${Math.round(seconds % 60)}s`;
  }
  return `${Math.round(seconds % 60)}s`;
}
