import { otherActions } from "state/actions/OtherActions";
import { gridLayoutActions } from "state/actions/LayoutMapActions";
import { orientationActions } from "state/actions/OrientationActions";
import { routingActions } from "state/actions/RoutingActions";
import { selectionActions } from "state/actions/SelectionActions";
import { SEARCH_MODE, ROUTE_MODE, HIDE_MODE } from "utils/constants";
import { initialState } from "state/StateManager";
import merge from "lodash.merge";

const reducerMode = (state: string, action: IAction): string => {
  const { type } = action;
  switch (type) {
    case otherActions.CHANGE_MODE_TO_SEARCH:
      return SEARCH_MODE;
    case otherActions.CHANGE_MODE_TO_ROUTE:
      return ROUTE_MODE;
    case otherActions.CHANGE_MODE_TO_HIDE:
      return HIDE_MODE;
    default:
      return state;
  }
};

const reducerLoading = (state: boolean, action: IAction): boolean => {
  const { type } = action;
  switch (type) {
    case otherActions.LOADING_DONE:
      return false;
    default:
      return state;
  }
};

const reducerGPS = (state: IGPS, action: IAction): IGPS => {
  const { type, payload } = action;
  switch (type) {
    case otherActions.LOCATION_ENABLE_CHANGE:
      return {
        ...state,
        enable: payload,
      };
    case otherActions.LOCATION_ENABLE_SWITCH:
      return state;
    case otherActions.CHANGE_GPS_POSITION:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

const reducerRouting = (state: IRouting, action: IAction): IRouting => {
  const { type, payload } = action;
  switch (type) {
    case routingActions.SEARCH_ROUTE:
      return {
        ...state,
        indexMainRoute: 0,
        routes: [],
        end: payload.end,
        start: payload.start,
      };
    case routingActions.NEW_ROUTES:
      const { routes, indexMainRoute } = payload;
      return {
        ...state,
        indexMainRoute,
        routes,
      };
    case routingActions.NEXT_ROUTE:
      return {
        ...state,
        showDuration: true,
        indexMainRoute: payload,
      };
    case routingActions.CHANGE_ROUTE:
      return {
        ...state,
        showDuration: true,
        indexMainRoute: payload,
      };
    case routingActions.ADD_ROUTING_START:
      return {
        ...state,
        start: payload,
      };
    case routingActions.ADD_ROUTING_END:
      return {
        ...state,
        end: payload,
      };
    case routingActions.DURATION_BOX_SWITCH:
      return {
        ...state,
        showDuration: !state.showDuration,
      };
    case routingActions.ERROR_NO_ENTRANCE:
      return {
        ...state,
        routes: [],
        end: payload.end,
        start: payload.start,
        warning: routingActions.ERROR_NO_ENTRANCE,
      };
    case routingActions.ERROR_SAME_BUILDING:
      return {
        ...state,
        routes: [],
        end: payload.end,
        start: payload.start,
        warning: routingActions.ERROR_SAME_BUILDING,
      };
    case routingActions.CLEAN_ROUTE_START:
      return {
        ...state,
        routes: [],
        start: "",
      };
    case routingActions.CLEAN_ROUTE_END:
      return {
        ...state,
        routes: [],
        end: "",
      };
    case otherActions.CHANGE_MODE_TO_SEARCH:
      return initialState.routing;
    case routingActions.CLEAN_ROUTE:
      return initialState.routing;
    case otherActions.RESET:
      return initialState.routing;
    default:
      return state;
  }
};

const reducerDatabase = (state: IDatabase, action: IAction): IDatabase => {
  const { type, payload } = action;
  switch (type) {
    case otherActions.DATABASE_NEW:
      return payload;
    case otherActions.DATABASE_UPDATE:
      return merge({}, state, payload);
    default:
      return state;
  }
};

const reducerSelection = (state: ISelection, action: IAction): ISelection => {
  const { type, payload } = action;
  switch (type) {
    case selectionActions.CREATE_SEARCH:
      const { locationName, hasEntrance } = payload;
      return {
        current: locationName,
        hasEntrance,
      };
    case selectionActions.SELECT_LOCATION:
      return {
        current: payload.locationName,
        hasEntrance: payload.hasEntrance,
      };
    case selectionActions.DESELECT_LOCATION:
      return {
        current: "",
        hasEntrance: false,
      };
    case selectionActions.CLEAN_SELECTION:
      return initialState.selection;
    case otherActions.RESET:
      return initialState.selection;
    default:
      return state;
  }
};

const reducerOrientation = (
  state: IOrientation,
  action: IAction,
): IOrientation => {
  const { type, payload } = action;
  switch (type) {
    case orientationActions.UPDATE_SCREEN_SIZE:
      const { width, height } = payload;
      return {
        ...state,
        width,
        height,
      };
    case orientationActions.UPDATE_ZOOM:
      return {
        ...state,
        zoom: payload,
      };
    case orientationActions.ADD_ROTATION:
      return {
        ...state,
        rotation: state.rotation + payload,
      };
    case orientationActions.UPDATE_ROTATION:
      return {
        ...state,
        rotation: payload,
      };
    case selectionActions.CREATE_SEARCH:
      return {
        ...state,
        rotation: 0,
      };
    case orientationActions.RESET_ROTATION:
      return {
        ...state,
        rotation: 0,
      };
    case otherActions.RESET:
      return {
        ...state,
        rotation: 0,
        zoom: 1,
      };
    default:
      return state;
  }
};

const reducerMap = (state: ILayoutMap, action: IAction): ILayoutMap => {
  const { type } = action;
  switch (type) {
    case gridLayoutActions.MAP_UPDATE:
      const { payload } = action;
      return payload;
    default:
      return state;
  }
};

const reducerRefAction = (state: IRefAction, action: IAction): IRefAction => {
  const { type, payload } = action;
  switch (type) {
    case selectionActions.CREATE_SEARCH: //Zoom and pan
      if (!payload.refAction) return state;
      return {
        ...state,
        onViewCenter: {
          trigger: !state.onViewCenter.trigger,
          x: payload.refAction.x,
          y: payload.refAction.y,
          zoom: payload.refAction.zoom,
        },
      };
    case routingActions.NEW_ROUTES:
      if (!payload.refAction) return state;
      return {
        ...state,
        onViewCenter: {
          trigger: !state.onViewCenter.trigger,
          x: payload.refAction.x,
          y: payload.refAction.y,
          zoom: payload.refAction.zoom,
        },
      };
    case otherActions.RESET:
      return {
        ...state,
        reset: !state.reset,
      };
    case otherActions.BLUR:
      return {
        ...state,
        blur: !state.blur,
      };
    default:
      return state;
  }
};

export default function combineReducer(state: IState, action: IAction): IState {
  return {
    mode: reducerMode(state.mode, action),
    loading: reducerLoading(state.loading, action),
    gps: reducerGPS(state.gps, action),
    database: reducerDatabase(state.database, action),
    selection: reducerSelection(state.selection, action),
    routing: reducerRouting(state.routing, action),
    orientation: reducerOrientation(state.orientation, action),
    layoutMap: reducerMap(state.layoutMap, action),
    refAction: reducerRefAction(state.refAction, action),
  };
}
