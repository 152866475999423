import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Fuse from "fuse.js";
import throttle from "lodash/throttle";
import { Container, Row, Col, InputGroup, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchLocation } from "@fortawesome/free-solid-svg-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDatabase, useRefAction } from "state/StateManager";
import { useActionCreateSearch } from "state/actions/SelectionActions";

const fuseOptions = {
  shouldSort: true,
  includeMatches: true,
  threshold: 0.5,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["mapid", "name", "keywords"],
};

const FloatWrapper = styled.div`
  position: absolute;
  top: 0rem;
  left: 0rem;
  right: 0rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  z-index: 40;
`;

/**
 * Search bar component for looking for buildings or locations in K1 Plant.
 * @param {function} openInfo Function to open About modal.
 */
const SearchLocation: React.FC = () => {
  const { t, i18n } = useTranslation();
  const refAction = useRefAction();
  const database = useDatabase();
  const createSearch = useActionCreateSearch();
  const [options, setOptions] = useState<any | boolean>(false);
  const [optionsFiltered, setOptionsFiltered] = useState([] as ILocationData[]);
  const searchLocationRef = useRef<any>(null);
  const [searchText, setSearchText] = useState("");

  //Filter with fuzzy search throttled
  const throttled = useRef(
    throttle((searchText: string, options: any | boolean) => {
      if (options) {
        const newOptions = options.search(searchText);
        setOptionsFiltered(newOptions.map((e: any) => e.item));
      }
    }, 1000),
  );

  useEffect(
    () => throttled.current(searchText, options),
    [searchText, options],
  );

  useEffect(() => {
    const fuse = new Fuse(Object.values(database), fuseOptions);
    setOptions(fuse);
  }, [database]);

  useEffect(() => {
    if (searchLocationRef.current && refAction.blur !== null)
      searchLocationRef.current.blur();
  }, [refAction.blur]);

  return (
    <FloatWrapper>
      <Container>
        <Row className="justify-content-center">
          <Col className="mx-auto scrollable">
            <InputGroup>
              <InputGroup.Prepend>
                <Button
                  onClick={() => {
                    if (searchLocationRef.current) {
                      searchLocationRef.current.focus();
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faSearchLocation} />
                </Button>
              </InputGroup.Prepend>
              <Typeahead
                inputProps={{ type: "search" }}
                id="Search"
                ref={searchLocationRef}
                placeholder={t("search-location")}
                multiple={false}
                options={optionsFiltered}
                filterBy={["mapid", "name", "keywords"]}
                labelKey={i18n.language === "ja" ? "translation" : "name"}
                minLength={1}
                selectHintOnEnter={true}
                onChange={(values: ILocationData[]) => {
                  if (values.length > 0) {
                    createSearch(values[0].mapid);
                    if (searchLocationRef.current) {
                      searchLocationRef.current.blur();
                    }
                  }
                }}
                onInputChange={(value) => setSearchText(value)}
              />
            </InputGroup>
          </Col>
        </Row>
      </Container>
    </FloatWrapper>
  );
};

export default SearchLocation;
