export const SEARCH_MODE: string = "search";
export const ROUTE_MODE: string = "route";
export const HIDE_MODE: string = "hide";

export const RED_COLOR: string = "#9f1924";
export const BLUE_COLOR: string = "#00677f";
export const ROUTE_COLOR: string = "#e24c00";
export const SUB_ROUTE_COLOR: string = "#bababa";

export const WIDTH_SVG: number = 170;
export const HEIGHT_SVG: number = 280;

export const SVGMETERS: number = 3.77;
export const WALKING_SPEED: number = 1.4;

export const PIN_OFFSET_X: number = 6.5;
export const PIN_OFFSET_Y: number = 20;
export const DURATION_BOX_OFFSET_X: number = -6.8594;
export const DURATION_BOX_OFFSET_Y: number = -15.642;

export const LATITUDE_MAX: number = 35.561277;
export const LATITUDE_MIN: number = 35.552428;
export const LONGITUDE_MAX: number = 139.669435;
export const LONGITUDE_MIN: number = 139.663666;
