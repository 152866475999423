/**
 * Convert GPS coordinates into a point in the SVG map.
 */
export default function GPStoSVGCoordinates(
  latitude: number,
  longitude: number,
): IPosition {
  const topRightCorner = {
    gps: {
      lat: 35.561124,
      long: 139.666962,
    },
    svg: {
      x: 104,
      y: 16,
    },
  };
  const bottomLeftCorner = {
    gps: {
      lat: 35.552502,
      long: 139.6641081,
    },
    svg: {
      x: 34,
      y: 270,
    },
  };
  const verticalScale =
    Math.abs(topRightCorner.svg.y - bottomLeftCorner.svg.y) /
    Math.abs(topRightCorner.gps.lat - bottomLeftCorner.gps.lat);
  const horizontalScale =
    Math.abs(topRightCorner.svg.x - bottomLeftCorner.svg.x) /
    Math.abs(topRightCorner.gps.long - bottomLeftCorner.gps.long);
  return {
    x:
      bottomLeftCorner.svg.x +
      (longitude - bottomLeftCorner.gps.long) * horizontalScale,
    y:
      topRightCorner.svg.y +
      (topRightCorner.gps.lat - latitude) * verticalScale,
  };
}
