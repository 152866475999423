import React from "react";
import { useOrientation, useGPS, useMode } from "state/StateManager";
import { HEIGHT_SVG, WIDTH_SVG, HIDE_MODE } from "utils/constants";
import useGeoLocation from "components/Map/UserLocation/useGeoLocation";

/**
 * Pin user position.
 */
const UserLocation: React.FC = () => {
  const orientation = useOrientation();
  const mode = useMode();
  const gps = useGPS();
  useGeoLocation();

  if (!gps.insideArea || !gps.enable || mode === HIDE_MODE) return <g />;
  const scale: number = 1 / orientation.zoom;
  const offset: number = 0.5;
  return (
    <svg viewBox={`0 0 ${WIDTH_SVG} ${HEIGHT_SVG}`}>
      <g
        transform={`rotate(${-orientation.rotation}, ${gps.positionX}, ${gps.positionY})`}
      >
        <circle
          cx={gps.positionX}
          cy={gps.positionY - 2 * scale}
          r={6.5 * scale}
          fill="#0275d8"
          opacity="0.5"
        />
        <text
          x={gps.positionX / (offset * scale)}
          y={gps.positionY / (offset * scale)}
          textAnchor="middle"
          fill="#0275d8"
          stroke="white"
          strokeWidth=".4"
          transform={`scale(${scale * offset})`}
        >
          &#xf21d;
        </text>
      </g>
    </svg>
  );
};

export default UserLocation;
