/**
 * Apply rotation to a point.
 */
export default function rotatePoint(
  point: number[],
  basePoint: number[],
  rotation_deg: number,
): IPosition {
  return {
    x:
      basePoint[0] +
      (point[1] - basePoint[1]) * Math.sin((-rotation_deg * Math.PI) / 180) +
      (point[0] - basePoint[0]) * Math.cos((-rotation_deg * Math.PI) / 180),
    y:
      basePoint[1] +
      (point[1] - basePoint[1]) * Math.cos((-rotation_deg * Math.PI) / 180) -
      (point[0] - basePoint[0]) * Math.sin((-rotation_deg * Math.PI) / 180),
  };
}
