import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Fuse from "fuse.js";
import queryString from "query-string";
import { useDatabase } from "state/StateManager";
import {
  useActionAddRoutingEnd,
  useActionAddRoutingStart,
} from "state/actions/RoutingActions";
import {
  useActionUpdateZoom,
  useActionUpdateRotation,
} from "state/actions/OrientationActions";
import { useActionCreateSearch } from "state/actions/SelectionActions";
import { useActionChangeMode } from "state/actions/OtherActions";
import { HIDE_MODE, SEARCH_MODE, ROUTE_MODE } from "utils/constants";

const fuseOptions = {
  shouldSort: true,
  includeMatches: true,
  threshold: 0.5,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["mapid", "name", "keywords"],
};

/**
 * Listener for URL API
 */
const useUrlAPI = () => {
  const location = useLocation();
  const database = useDatabase();
  const addRoutingEnd = useActionAddRoutingEnd();
  const addRoutingStart = useActionAddRoutingStart();
  const changeMode = useActionChangeMode();
  const createSearch = useActionCreateSearch();
  const updateRotation = useActionUpdateRotation();
  const updateZoom = useActionUpdateZoom();

  //Search a location or create a route automatically, by args passed through URL
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const mode = parsed.mode;
    const start = parsed.from;
    const end = parsed.to;
    const select = parsed.select;
    const rotation = parsed.rotation;
    const zoom = parsed.zoom;
    const fuse = new Fuse(Object.values(database), fuseOptions);

    if (mode === "route") changeMode(ROUTE_MODE);
    else if (mode === "search") changeMode(SEARCH_MODE);
    else if (mode === "hide") changeMode(HIDE_MODE);

    if (typeof start === "string" && typeof end === "string") {
      const startLocationPossibilities: any = fuse.search(start);
      const endLocationPossibilities: any = fuse.search(end);
      if (
        startLocationPossibilities.length &&
        endLocationPossibilities.length
      ) {
        addRoutingStart(startLocationPossibilities[0].item.mapid);
        addRoutingEnd(endLocationPossibilities[0].item.mapid);
      }
    } else if (typeof select === "string") {
      const selectedLocationPossibilities: any = fuse.search(select);
      if (selectedLocationPossibilities.length) {
        createSearch(selectedLocationPossibilities[0].item.mapid);
      }
    }

    if (typeof rotation === "string" && parseInt(rotation)) {
      updateRotation(parseInt(rotation));
    }

    if (typeof zoom === "string" && parseInt(zoom)) {
      updateZoom(parseInt(zoom));
    }
  }, [
    location,
    createSearch,
    database,
    changeMode,
    addRoutingEnd,
    addRoutingStart,
    updateRotation,
    updateZoom,
  ]);
};

export default useUrlAPI;
