import { useEffect } from "react";
import GPStoSVG from "utils/GPStoSVGCooridnates";
import { useGPS, useMode } from "state/StateManager";
import {
  useActionSwitchGPSEnable,
  useActionGPSUpdatePosition,
} from "state/actions/OtherActions";
import {
  LATITUDE_MAX,
  LATITUDE_MIN,
  LONGITUDE_MAX,
  LONGITUDE_MIN,
  HIDE_MODE,
} from "utils/constants";

/**
 * Update user position.
 */
export default function useGeoLocation() {
  const gps = useGPS();
  const switchGPSEnable = useActionSwitchGPSEnable();
  const gpsUpdatePosition = useActionGPSUpdatePosition();
  const mode = useMode();
  let watchID: null | number = null;

  useEffect(() => {
    if (navigator.geolocation && gps.enable && mode !== HIDE_MODE) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      watchID = navigator.geolocation.watchPosition(
        (pos) => {
          let crd = pos.coords;
          if (crd.accuracy < 100) {
            if (
              crd.latitude < LATITUDE_MAX &&
              crd.latitude > LATITUDE_MIN &&
              crd.longitude < LONGITUDE_MAX &&
              crd.longitude > LONGITUDE_MIN
            ) {
              const position = GPStoSVG(crd.latitude, crd.longitude);
              gpsUpdatePosition(true, position.x, position.y);
            } else {
              console.log("Location outside K1 Plant.");
              gpsUpdatePosition(false);
            }
          } else {
            console.log(`GPS low accuracy (${crd.accuracy} meters).`);
            switchGPSEnable(false);
          }
          return false;
        },
        (error: any) => {
          switchGPSEnable(false);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.log("User denied the request for Geolocation.");
              break;
            case error.POSITION_UNAVAILABLE:
              console.log("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              console.log("The request to get user location timed out.");
              break;
            case error.UNKNOWN_ERROR:
              console.log("An unknown error occurred.");
              break;
          }
        },
        { enableHighAccuracy: true },
      );
    }
    return () => {
      if (watchID) navigator.geolocation.clearWatch(watchID);
    };
  }, []);

  useEffect(() => {
    if (
      navigator.geolocation &&
      watchID &&
      (!gps.enable || mode === HIDE_MODE)
    ) {
      navigator.geolocation.clearWatch(watchID);
    }
  }, [gps.enable, watchID, mode]);
}
