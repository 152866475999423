import { HEIGHT_SVG, WIDTH_SVG } from "utils/constants";
/**
 * Convert SVG coordinates into a point in the Screen.
 */
export default function SVGtoScreenCoordinates(
  x: number,
  y: number,
  screenSizeWidth: number,
  screenSizeHeight: number,
): IPosition {
  const widthScale = screenSizeWidth / WIDTH_SVG;
  const heightScale = screenSizeHeight / HEIGHT_SVG;
  const scale = widthScale < heightScale ? widthScale : heightScale;
  return {
    x: screenSizeWidth / 2 + (-(WIDTH_SVG / 2) + x) * scale,
    y: screenSizeHeight / 2 + (-(HEIGHT_SVG / 2) + y) * scale,
  };
}
