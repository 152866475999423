import React from "react";
import { useOrientation } from "state/StateManager";
import rotatePoint from "utils/rotatePoint";
import {
  HEIGHT_SVG,
  WIDTH_SVG,
  PIN_OFFSET_X,
  PIN_OFFSET_Y,
} from "utils/constants";

interface IProps {
  x: number;
  y: number;
  color: string;
}
/**
 * Pin element.
 */
const Pin: React.FC<IProps> = (props) => {
  const { x, y, color } = props;
  const orientation = useOrientation();
  const position = rotatePoint([x, y], [0, 0], orientation.rotation);
  const scale = 1 / orientation.zoom;
  const off_set_x = PIN_OFFSET_X * scale;
  const off_set_y = PIN_OFFSET_Y * scale;
  return (
    <svg
      viewBox={`0 0 ${WIDTH_SVG} ${HEIGHT_SVG}`}
      style={{ overflow: "visible" }}
    >
      <g
        id="pin"
        transform={`rotate(${-orientation.rotation}) translate(${position.x - off_set_x},
                        ${position.y - off_set_y}) scale(${scale})`}
      >
        <g
          id="g6"
          transform="matrix(0.03808586,0,0,0.0390625,-3.244933,0.00723911)"
        >
          <path
            d="M 256,0 C 161.896,0 85.333,76.563 85.333,170.667 c 0,28.25 7.063,56.26 20.49,81.104 L 246.667,506.5 c 1.875,3.396 5.448,5.5 9.333,5.5 3.885,0 7.458,-2.104 9.333,-5.5 L 406.229,251.687 c 13.375,-24.76 20.438,-52.771 20.438,-81.021 C 426.667,76.563 350.104,0 256,0 Z m 0,256 c -47.052,0 -85.333,-38.281 -85.333,-85.333 0,-47.052 38.281,-85.333 85.333,-85.333 47.052,0 85.333,38.281 85.333,85.333 C 341.333,217.719 303.052,256 256,256 Z"
            id="path2"
            fill={color}
          />
        </g>
        <path
          fill="#ffffff"
          d="M 6.0856937,9.9663015 C 5.3513228,9.8635871 4.7077002,9.524734 4.1776884,8.9622421 3.7340767,8.4911556 3.4505516,7.9357433 3.3241494,7.2895856 3.2860289,7.0927654 3.2779368,6.980761 3.2779368,6.6567372 3.2808617,6.2095695 3.3049429,6.0282485 3.4260122,5.6362481 3.7711248,4.5225137 4.7023575,3.6509472 5.8166077,3.3988998 6.972108,3.1377228 8.1863297,3.5455925 8.9620969,4.455617 9.8462105,5.4927353 10.025376,6.9522424 9.4181588,8.1702116 8.7871423,9.4354884 7.448556,10.156932 6.0861909,9.9663015 Z"
          id="path852"
        />
      </g>
    </svg>
  );
};

export default Pin;
