import React from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useOrientation } from "state/StateManager";
import { useTranslation } from "react-i18next";
import useLongPress from "components/Menu/NavigationButtons/useLongPress";
import { useActionResetOrientation } from "state/actions/OrientationActions";
import { ReactComponent as CompassImage } from "assets/compass.svg";
import TooltipOverlay from "components/TooltipOverlay";

const CompassSVG = styled(CompassImage)<{ rotation: number }>`
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(${(props: any) => props.rotation}deg);
`;

const FloatIcon = styled.div`
  position: absolute;
  bottom: 3.5rem;
  left: 0rem;
  margin: 0.5rem;
  text-align: center;
  z-index: 10;
  font-size: 1.75rem;
  padding: 0.8rem !important;

  &:hover {
    cursor: pointer;
  }
`;
/**
 * Map's compass
 */
const Compass: React.FC = () => {
  const { t } = useTranslation();
  const orientation = useOrientation();
  const resetOrientation = useActionResetOrientation();
  const backspaceLongPress = useLongPress(
    () => toast.info(t("compass-description")),
    500,
  );

  return (
    <div {...backspaceLongPress}>
      <TooltipOverlay placement="right" description="compass-description">
        <FloatIcon onClick={() => resetOrientation()}>
          <span className="fa-layers fa-fw">
            <CompassSVG rotation={orientation.rotation} />
          </span>
        </FloatIcon>
      </TooltipOverlay>
    </div>
  );
};

export default Compass;
