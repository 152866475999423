import React, { useEffect, useState } from "react";
import FusoSpinner from "components/Loader/FusoSpinner";
import LoadRoutes from "components/Loader/LoadRoutes";
import LoadPositions from "components/Loader/LoadPositions";
import LoadEntrances from "components/Loader/LoadEntrances";
import LoadSVG from "components/Loader/LoadSVG";
import useLoadDatabase from "components/Loader/useLoadDatabase";
import { useActionLoadingDone } from "state/actions/OtherActions";

interface IProps {
  mapSVG: string;
}

/**
 * Load dababase and integrate svg infomation into the database.
 */
const Loading: React.FC<IProps> = ({ mapSVG }) => {
  const [buildingsDone, setBuildingsDone] = useState(false);
  const [locationssDone, setLocationsDone] = useState(false);
  const [entrancesDone, setEntrancesDone] = useState(false);
  const [routesDone, setRoutesDone] = useState(false);
  const [databaseDone, setDatabaseDone] = useState(false);
  const loadingDone = useActionLoadingDone();

  useLoadDatabase(setDatabaseDone);

  useEffect(() => {
    if (
      buildingsDone &&
      locationssDone &&
      entrancesDone &&
      routesDone &&
      databaseDone
    ) {
      console.log("Loading Complete");
      loadingDone();
    }
  }, [
    buildingsDone,
    locationssDone,
    entrancesDone,
    routesDone,
    databaseDone,
    loadingDone,
  ]);

  return (
    <div>
      <FusoSpinner />
      {databaseDone && (
        <LoadSVG mapSVG={mapSVG}>
          <LoadPositions
            groupName={"buildings"}
            onFinish={() => setBuildingsDone(true)}
          />
          <LoadPositions
            groupName={"locations"}
            onFinish={() => setLocationsDone(true)}
          />
          <LoadEntrances onFinish={() => setEntrancesDone(true)} />
          <LoadRoutes onFinish={() => setRoutesDone(true)} />
        </LoadSVG>
      )}
    </div>
  );
};

export default Loading;
