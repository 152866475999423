import { useEffect } from "react";
import { useActionNewDatabase } from "state/actions/OtherActions";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const GET_LOCATIONS = gql`
  {
    locations {
      name
      translation
      mapid
      keywords
      floor
      type
      belongsTo {
        mapid
      }
      has {
        mapid
      }
    }
  }
`;
/**
 * Load database and save it on application's state.
 */
const useLoadDatabase = (callback: (success: boolean) => void) => {
  const newDatabase = useActionNewDatabase();
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(GET_LOCATIONS);

  useEffect(() => {
    if (data && !error && !loading) {
      const info = data.locations.reduce((acc: any, item: any) => {
        return {
          ...acc,
          [item.mapid]: {
            name: item.name || "",
            translation: item.translation || "",
            mapid: item.mapid || "",
            keywords: item.keywords?.join(";") || [],
            floor: item.floor?.join("&") || [],
            type: item.type || "",
            belongs: item.belongsTo?.mapid || "",
            has: item.has?.map((i: any) => i.mapid) || [],
          },
        };
      }, {});
      newDatabase(info);
      callback(true);
      console.log("Database: Loaded.");
    } else if (error && !loading) {
      toast.error(t("application-error"));
      console.error(error);
      callback(false);
    }
  }, [callback, newDatabase, data, loading, error, t]);
};

export default useLoadDatabase;
