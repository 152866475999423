import React, { createContext, useContext, useReducer } from "react";
import { SEARCH_MODE } from "utils/constants";
import combineReducer from "state/ReducersManager";

export const initialState: IState = {
  mode: SEARCH_MODE,
  loading: true,
  database: {},
  gps: { enable: true, insideArea: false, positionX: 0, positionY: 0 },
  selection: { current: "", hasEntrance: false },
  routing: {
    start: "",
    end: "",
    indexMainRoute: 0,
    routes: [],
    showDuration: true,
    warning: "",
  },
  orientation: { rotation: 0, zoom: 1, width: 0, height: 0 },
  layoutMap: { matrix: null, walkablePoints: null },
  refAction: {
    onViewCenter: {
      trigger: null,
      x: 0,
      y: 0,
      zoom: 1,
    },
    reset: null,
    blur: null,
  },
};

export const StateContext = createContext<any>({} as IState);
export const DispatchContext = createContext<React.Dispatch<IAction>>(
  {} as React.Dispatch<IAction>,
);
export const ModeContext = createContext<String>(initialState.mode);
export const LoadingContext = createContext<Boolean>(initialState.loading);
export const DatabaseContext = createContext<IDatabase>(initialState.database);
export const GPSContext = createContext<IGPS>(initialState.gps);
export const SelectionContext = createContext<ISelection>(
  initialState.selection,
);
export const RoutingContext = createContext<IRouting>(initialState.routing);
export const OrientationContext = createContext<IOrientation>(
  initialState.orientation,
);
export const LayoutMapContext = createContext<ILayoutMap>(
  initialState.layoutMap,
);
export const RefActionContext = createContext<IRefAction>(
  initialState.refAction,
);

interface IProps {
  children: React.ReactNode;
}
/**
 * Context provider for app"s state. Flux architecture.
 */
export const StateManager: React.FC<IProps> = ({ children }) => {
  const [state, dispatch] = useReducer(combineReducer, initialState);

  return (
    <DatabaseContext.Provider value={state.database}>
      <OrientationContext.Provider value={state.orientation}>
        <LoadingContext.Provider value={state.loading}>
          <ModeContext.Provider value={state.mode}>
            <RoutingContext.Provider value={state.routing}>
              <SelectionContext.Provider value={state.selection}>
                <LayoutMapContext.Provider value={state.layoutMap}>
                  <GPSContext.Provider value={state.gps}>
                    <RefActionContext.Provider value={state.refAction}>
                      <DispatchContext.Provider value={dispatch}>
                        {children}
                      </DispatchContext.Provider>
                    </RefActionContext.Provider>
                  </GPSContext.Provider>
                </LayoutMapContext.Provider>
              </SelectionContext.Provider>
            </RoutingContext.Provider>
          </ModeContext.Provider>
        </LoadingContext.Provider>
      </OrientationContext.Provider>
    </DatabaseContext.Provider>
  );
};

export const useDispatchFunction = () => useContext(DispatchContext);
export const useMode = () => useContext(ModeContext);
export const useLoading = () => useContext(LoadingContext);
export const useDatabase = () => useContext(DatabaseContext);
export const useGPS = () => useContext(GPSContext);
export const useSelection = () => useContext(SelectionContext);
export const useRouting = () => useContext(RoutingContext);
export const useOrientation = () => useContext(OrientationContext);
export const useLayoutMap = () => useContext(LayoutMapContext);
export const useRefAction = () => useContext(RefActionContext);
