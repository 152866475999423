/**
 * Get the building where teh given location reside.
 */
export default function getBuilding(
  locationId: string,
  database: IDatabase,
): ILocationData | null {
  let locationObject: ILocationData = database[locationId];
  if (!locationObject) {
    console.error(`Location (${locationId}) not found on db.`);
    return null;
  }
  let belongsTo = database[locationObject!.belongs];
  if (!belongsTo) {
    console.error(`Location (${locationObject.belongs}) not found on db.`);
    return null;
  }
  while (belongsTo.type !== "AREA") {
    locationObject = belongsTo;
    belongsTo = database[locationObject.belongs];
    if (!belongsTo) {
      console.error(`Location (${locationObject.belongs}) not found on db.`);
      return null;
    }
  }
  return locationObject;
}
