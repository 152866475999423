import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Disclaimer from "components/Menu/Disclaimer/Disclaimer";
import Search from "components/Menu/SearchBar/Search";
import NavigationButtons from "components/Menu/NavigationButtons/NavigationButtons";
import TrackIcon from "components/Menu/TrackIcon";
import InfoWindow from "components/Menu/InfoWindow";
import Compass from "components/Menu/Compass";
import LocationDescription from "components/Menu/LocationDescription";
import { useMode, useRouting, useSelection } from "state/StateManager";
import { HIDE_MODE } from "utils/constants";

/**
 * Component responsible for managing all the inputs received from the user.
 */
const Menu: React.FC = () => {
  const { t } = useTranslation();
  const [firstTime, setFirstTime] = useState(true);
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const [infoOpen, setInfoOpen] = useState(false);
  const mode = useMode();
  const routing = useRouting();
  const selection = useSelection();

  useEffect(() => {
    if (localStorage.getItem("disclaimerMap")) {
      setShowDisclaimer(false);
    }
  }, []);

  useEffect(() => {
    if (firstTime && !showDisclaimer && mode !== HIDE_MODE) {
      toast.info(t("tutorial"));
      setFirstTime(false);
    }
  }, [showDisclaimer, firstTime, t, mode]);

  useEffect(() => {
    window.history.pushState(
      null,
      "",
      `?mode=${mode}${selection.current ? `&select=${selection.current}` : ""}${routing.start ? `&from=${routing.start}` : ""}${routing.end ? `&to=${routing.end}` : ""}`,
    );
  }, [mode, selection, routing]);

  if (mode === HIDE_MODE) {
    return <div />;
  }

  return (
    <div>
      <Search />
      <NavigationButtons showInfo={() => setInfoOpen(true)} />
      <LocationDescription />
      <TrackIcon />
      <Compass />
      <InfoWindow show={infoOpen} onHide={() => setInfoOpen(false)} />
      <Disclaimer
        show={showDisclaimer}
        onHide={() => setShowDisclaimer(false)}
      />
    </div>
  );
};

export default Menu;
