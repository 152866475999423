import React from "react";
import { parseSVG, makeAbsolute } from "svg-path-parser";
import { SvgProxy } from "react-svgmt";
import { useActionAddOnDatabase } from "state/actions/OtherActions";

interface IProps {
  onFinish: () => void;
}
/**
 * Load all entrances position into the database.
 */
const LoadEntrances: React.FC<IProps> = ({ onFinish }) => {
  const addOnDatabase = useActionAddOnDatabase();

  const getPositionOnSVG = (element: Element) => {
    const commands = parseSVG(element.getAttribute("d") || "");
    makeAbsolute(commands);
    const centerSum = commands.reduce(
      (acc, e: any) => {
        return { x: acc.x + e.x, y: acc.y + e.y };
      },
      { x: 0, y: 0 },
    );
    return {
      x: centerSum.x / commands.length,
      y: centerSum.y / commands.length,
    };
  };

  return (
    <SvgProxy
      selector="#entrances"
      onElementSelected={(e: Element) => {
        if (e.children) {
          const entrances = Array.from(e.children);
          const newDatabase = entrances.reduce(
            (acc: IDatabase, entrance: Element) => {
              const id = entrance.id.split("Entrance")[0];
              const oldEntrances = acc[id] ? acc[id].entrances : [];
              return {
                ...acc,
                [id]: {
                  ...({} as ILocationData),
                  entrances: [...oldEntrances, getPositionOnSVG(entrance)],
                },
              };
            },
            {} as IDatabase,
          );
          addOnDatabase(newDatabase);
          onFinish();
        }
      }}
    />
  );
};

export default LoadEntrances;
