import { useCallback } from "react";
import { useDispatchFunction } from "state/StateManager";
import { SEARCH_MODE, ROUTE_MODE, HIDE_MODE } from "utils/constants";

export const LOADING_DONE: string = "loadingDone";
export const DATABASE_NEW: string = "databaseNew";
export const DATABASE_UPDATE: string = "databaseUpdate";
export const LOCATION_ENABLE_SWITCH: string = "locationEnableSwitch";
export const LOCATION_ENABLE_CHANGE: string = "locationEnableChange";
export const CHANGE_MODE_TO_SEARCH: string = "changeModeToSearch";
export const CHANGE_MODE_TO_ROUTE: string = "changeModeToRoute";
export const CHANGE_MODE_TO_HIDE: string = "changeModeToHide";
export const CHANGE_GPS_POSITION: string = "changeGPSPosition";
export const RESET: string = "reset";
export const BLUR: string = "blur";

export const otherActions = {
  LOADING_DONE,
  DATABASE_NEW,
  DATABASE_UPDATE,
  LOCATION_ENABLE_SWITCH,
  LOCATION_ENABLE_CHANGE,
  CHANGE_MODE_TO_SEARCH,
  CHANGE_MODE_TO_ROUTE,
  CHANGE_MODE_TO_HIDE,
  CHANGE_GPS_POSITION,
  RESET,
  BLUR,
};
/**
 * Action to change application mode.
 */
export function useActionChangeMode(): (mode: string) => void {
  const dispatch = useDispatchFunction();
  return useCallback(
    (mode: string) => {
      let actionType: string = "";
      if (mode === SEARCH_MODE) actionType = CHANGE_MODE_TO_SEARCH;
      else if (mode === ROUTE_MODE) actionType = CHANGE_MODE_TO_ROUTE;
      else if (mode === HIDE_MODE) actionType = CHANGE_MODE_TO_HIDE;
      const action = {
        type: actionType,
      };
      dispatch(action);
    },
    [dispatch],
  );
}

/**
 * Action to desactive loading status.
 */
export function useActionLoadingDone(): () => void {
  const dispatch = useDispatchFunction();
  return useCallback(() => {
    const action = {
      type: LOADING_DONE,
    };
    dispatch(action);
  }, [dispatch]);
}

/**
 * Action to reset application state.
 */
export function useActionReset() {
  const dispatch = useDispatchFunction();
  return useCallback(() => {
    const action = {
      type: RESET,
    };
    dispatch(action);
  }, [dispatch]);
}

/**
 * Action to change permissions on GPS configuration.
 */
export function useActionSwitchGPSEnable() {
  const dispatch = useDispatchFunction();
  return useCallback(
    (status?: boolean) => {
      const action = {
        type: LOCATION_ENABLE_CHANGE,
        payload: status,
      };
      dispatch(action);
    },
    [dispatch],
  );
}

/**
 * Action to update where the user is.
 */
export function useActionGPSUpdatePosition() {
  const dispatch = useDispatchFunction();
  return useCallback(
    (insideArea: boolean, positionX?: number, positionY?: number) => {
      const action = {
        type: CHANGE_GPS_POSITION,
        payload: {
          insideArea,
          positionX,
          positionY,
        },
      };
      dispatch(action);
    },
    [dispatch],
  );
}

////Database Actions
/**
 * Action to add new Database.
 */
export function useActionNewDatabase() {
  const dispatch = useDispatchFunction();
  return useCallback(
    (database: IDatabase) => {
      const action = {
        type: DATABASE_NEW,
        payload: database,
      };
      dispatch(action);
    },
    [dispatch],
  );
}

/**
 * Action to complement Database.
 */
export function useActionAddOnDatabase() {
  const dispatch = useDispatchFunction();

  return useCallback(
    (databaseAddon: IDatabase) => {
      const action = {
        type: DATABASE_UPDATE,
        payload: databaseAddon,
      };
      dispatch(action);
    },
    [dispatch],
  );
}

/**
 * Action to blur all inputs.
 */
export function useActionBlur() {
  const dispatch = useDispatchFunction();
  return useCallback(() => {
    const action = {
      type: BLUR,
    };
    dispatch(action);
  }, [dispatch]);
}
