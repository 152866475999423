import { useDispatchFunction } from "state/StateManager";

export const MAP_UPDATE: string = "mapUpdate";

export const gridLayoutActions = {
  MAP_UPDATE,
};

/**
 * Action to add map layout information
 */
export function useActionAddLayoutMap(): (mapInfo: ILayoutMap) => void {
  const dispatch = useDispatchFunction();
  return (mapInfo: ILayoutMap) => {
    const action = {
      type: MAP_UPDATE,
      payload: mapInfo,
    };
    dispatch(action);
  };
}
