import React, { useState, useEffect, useRef } from "react";
import { ReactSVGPanZoom, INITIAL_VALUE } from "react-svg-pan-zoom";
import { fromObject } from "transformation-matrix";
import { useOrientation, useRefAction } from "state/StateManager";
import {
  useActionUpdateZoom,
  useActionAddRotationDiff,
} from "state/actions/OrientationActions";
import PinLocations from "components/Map/PinLocations/PinLocations";
import UserLocation from "components/Map/UserLocation/UserLocation";
import MapRoutes from "components/Map/MapRoutes/MapRoutes";
import DurationBox from "components/Map/MapRoutes/DurationBox";
import SelectableElements from "components/Map/SelectableElements";
import { getRotationFactor } from "utils/getRotationFactor";
import { useActionBlur } from "state/actions/OtherActions";

interface IProps {
  mapSVG: string;
}
/**
 * Render map viewer (pan, zoom, rotate and select).
 */
const Map: React.FC<IProps> = ({ mapSVG }) => {
  const svgViewerRef = useRef<any>(null);
  const refAction = useRefAction();
  const orientation = useOrientation();
  const updateZoom = useActionUpdateZoom();
  const addRotationDiff = useActionAddRotationDiff();
  const blur = useActionBlur();

  const [value, setValue] = useState(INITIAL_VALUE);
  const [previousTouch, setPreviousTouch] = useState({} as React.TouchList);
  const [selectionDisable, setSelectionDisable] = useState(false);

  useEffect(() => {
    if (refAction.onViewCenter.trigger !== null && svgViewerRef.current) {
      svgViewerRef.current.setPointOnViewerCenter(
        refAction.onViewCenter.x,
        refAction.onViewCenter.y,
        refAction.onViewCenter.zoom,
      );
    }
  }, [refAction.onViewCenter]);

  useEffect(() => {
    if (refAction.reset !== null && svgViewerRef.current) {
      svgViewerRef.current.reset();
    }
  }, [refAction.reset]);

  return (
    <ReactSVGPanZoom
      ref={svgViewerRef}
      value={value}
      onChangeTool={() => null}
      onChangeValue={(value: any) => {
        const { a } = fromObject(value);
        if (orientation.zoom !== a) {
          updateZoom(a);
        }
        setValue(value);
      }}
      background="white"
      tool="auto"
      detectAutoPan={false}
      miniatureProps={{
        position: "none",
        background: "white",
        width: 0,
        height: 0,
      }}
      toolbarProps={{ position: "none" }}
      scaleFactorOnWheel={1.2}
      scaleFactorMax={5}
      scaleFactorMin={1}
      onClick={() => blur()}
      onTouchStart={({ originalEvent: { touches } }: any) => {
        blur();
        if (selectionDisable) setSelectionDisable(false);
        if (touches.length === 2) setPreviousTouch(touches);
        else setPreviousTouch({} as React.TouchList);
      }}
      onTouchMove={({ originalEvent: { touches } }: any) => {
        if (!selectionDisable) setSelectionDisable(true);
        if (touches.length === 2 && previousTouch) {
          const rotationDiff = getRotationFactor(touches, previousTouch);
          addRotationDiff(rotationDiff);
        }
        if (touches.length === 2) setPreviousTouch(touches);
        else setPreviousTouch({} as React.TouchList);
      }}
      width={orientation.width}
      height={orientation.height}
    >
      <svg width={orientation.width} height={orientation.height}>
        <g
          transform={`rotate(${orientation.rotation} ${orientation.width / 2} ${orientation.height / 2})`}
        >
          <image href={mapSVG} height="100%" width="100%" />
          <SelectableElements
            svg={mapSVG}
            elements={["buildings", "locations"]}
            selectionDisable={selectionDisable}
            invisible
          />
          <MapRoutes />
          <PinLocations />
          <DurationBox />
          <UserLocation />
        </g>
      </svg>
    </ReactSVGPanZoom>
  );
};

export default Map;
