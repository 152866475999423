// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from "worker-loader!worker/Worker.js";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useRouting, useMode } from "state/StateManager";
import Route from "components/Map/MapRoutes/Route";
import {
  useActionAddRoutes,
  useActionSearchRoute,
} from "state/actions/RoutingActions";
import { SEARCH_MODE, ROUTE_MODE } from "utils/constants";
import { useActionChangeMode } from "state/actions/OtherActions";

const worker = new Worker();

interface IData {
  data: {
    type: string;
    payload: any;
  };
}

/**
 * Show all the routes to the user.
 */
const MapRoutes: React.FC = () => {
  const { t } = useTranslation();
  const routing = useRouting();
  const addRoutes = useActionAddRoutes();
  const searchRoute = useActionSearchRoute();
  const mode = useMode();
  const changeMode = useActionChangeMode();

  // Add listener for web worker
  useEffect(() => {
    const listener = (data: IData) => {
      const {
        data: { type, payload },
      } = data;
      if (type === "CALCULATE_PATH_SUCCESS") {
        const { routes, indexMainRoute } = payload;
        addRoutes(routes, indexMainRoute);
      }
    };
    worker.addEventListener("message", listener);
    return () => worker.removeEventListener("message", listener);
  }, [addRoutes]);

  useEffect(() => {
    if (routing.warning) {
      toast.error(t(routing.warning));
    }
  }, [routing.warning, t]);

  // Find a route if there are a start and end point defined
  useEffect(() => {
    if (routing.start && routing.end) {
      const msg = searchRoute(routing.start, routing.end);
      if (mode === SEARCH_MODE) {
        changeMode(ROUTE_MODE);
      }
      if (msg) worker.postMessage(msg);
    }
  }, [routing.end, routing.start, searchRoute, mode, changeMode]);

  return useMemo(() => {
    if (!routing.routes.length) return <g />;
    return (
      <g id="map-routes">
        {routing.routes.map((route: IRoute, index: number) => {
          if (index !== routing.indexMainRoute) {
            return (
              <Route
                key={`route-secondary-${index}`}
                route={route}
                type="secondary"
                id={index}
                dashed={false}
              />
            );
          }
          return <g key={`route-${index}`} />;
        })}
        <Route
          key="route-main"
          route={routing.routes[routing.indexMainRoute]}
          type="main"
          id={routing.indexMainRoute}
          dashed={false}
        />
      </g>
    );
  }, [routing]);
};

export default MapRoutes;
