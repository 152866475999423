import React, { useEffect } from "react";
import mapSVG from "assets/Map.svg";
import Loading from "components/Loader/Loading";
import MenuInterface from "components/Menu/Menu";
import MapInterface from "components/Map/Map";
import { useActionUpdateScreenSize } from "state/actions/OrientationActions";
import { useLoading } from "state/StateManager";
import useUrlAPI from "components/Menu/useUrlAPI";

/**
 * Main app's component.
 */
const Main: React.FC = () => {
  const loading = useLoading();
  const updateScreenSize = useActionUpdateScreenSize();

  useUrlAPI(); // Check for API URL

  //Listening for changes on screen size
  useEffect(() => {
    function updateSize() {
      updateScreenSize(window.innerWidth, window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [updateScreenSize]);

  if (loading) return <Loading mapSVG={mapSVG} />;
  return (
    <div className="parent-size">
      <MapInterface mapSVG={mapSVG} />
      <MenuInterface />
    </div>
  );
};

export default Main;
