import React from "react";
import { BrowserRouter } from "react-router-dom";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";
import { toast } from "react-toastify";
import { StateManager } from "state/StateManager";
import Main from "components/Main";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  position: "bottom-center",
  autoClose: 5000,
  draggable: false,
  closeButton: false,
  closeOnClick: true,
  hideProgressBar: true,
});

const client = new ApolloClient();

function App() {
  return (
    <ApolloProvider client={client}>
      <StateManager>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </StateManager>
    </ApolloProvider>
  );
}

export default App;
