import React from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlash, faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { useGPS } from "state/StateManager";
import { useTranslation } from "react-i18next";
import useLongPress from "components/Menu/NavigationButtons/useLongPress";
import { useActionSwitchGPSEnable } from "state/actions/OtherActions";
import TooltipOverlay from "components/TooltipOverlay";

const FloatIcon = styled.div`
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  margin: 1rem;
  text-align: center;
  z-index: 10;
  font-size: 1rem;
  padding: 0.8rem !important;

  &:hover {
    cursor: pointer;
  }
`;

/**
 * Show if GPS Tracking is active or not, as well as, active/desactive GPS Tracking.
 */
const TrackIcon: React.FC = () => {
  const { t } = useTranslation();
  const gps = useGPS();
  const switchGPSEnable = useActionSwitchGPSEnable();
  const backspaceLongPress = useLongPress(
    () => toast.info(t("gps-description")),
    500,
  );

  return (
    <div {...backspaceLongPress}>
      <TooltipOverlay placement="right" description="gps-description">
        <FloatIcon
          onClick={() => {
            switchGPSEnable(!gps.enable);
            toast.info(t(gps.enable ? "gps-deactivate" : "gps-activate"));
          }}
        >
          <span className="fa-layers fa-fw">
            <FontAwesomeIcon
              color={gps.insideArea ? "#0275d8" : "gray"}
              transform="grow-5"
              icon={faMapMarkedAlt}
            />
            {!gps.enable && (
              <FontAwesomeIcon
                color={gps.insideArea ? "#0275d8" : "gray"}
                transform="grow-5"
                icon={faSlash}
              />
            )}
          </span>
        </FloatIcon>
      </TooltipOverlay>
    </div>
  );
};

export default TrackIcon;
