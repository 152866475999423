import React, { useState, useEffect } from "react";
import { Modal, Button, ListGroup } from "react-bootstrap";
import styled from "styled-components";
import DisclaimerText from "components/Menu/Disclaimer/DisclaimerText";
import { useTranslation } from "react-i18next";
import mapLegendJa from "assets/MapLegendJa.svg";
import mapLegendEn from "assets/MapLegendEn.svg";
import { version } from "../../../package.json";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const ScrollBody = styled(Modal.Body)`
  max-height: 50vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: auto;
`;

const Center = styled.div`
  text-align: center;
`;

const Text = styled.p`
  white-space: pre-line;
  word-break: break-word;
`;

const MapLegend = styled.img`
  height: 50vh;
`;

const Link = styled.p`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const RightText = styled.small`
  float: right;
  color: gray;
  display: flex;
`;

const ClickListItem = styled(ListGroup.Item)`
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
`;

const GET_API_VERSION = gql`
  {
    version
  }
`;

interface IProps {
  onHide: () => void;
  show: boolean;
}

/**
 * Modal with detailed information about the application. Version, language, authors and attributions.
 */
const InfoWindow: React.FC<IProps> = (props) => {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);
  const [licenseText, setLisenceText] = useState("");
  const [legendLoading, setLegendLoading] = useState(true);
  const { loading, error, data } = useQuery(GET_API_VERSION);

  useEffect(() => setLegendLoading(true), [i18n.language]);

  const getTitle = (page: number) => {
    switch (page) {
      default:
        return t("general-information");
      case 2:
        return t("open-source-licenses");
      case 3:
        return t("tos");
      case 4:
        return t("legend");
    }
  };

  const renderViews = () => {
    if (page === 0) {
      return (
        <ListGroup variant="flush">
          <ListGroup.Item>
            {t("version")}
            <RightText>{`Client ${version} / API ${loading ? "loading..." : error ? "error" : data.version} `}</RightText>
          </ListGroup.Item>
          <ListGroup.Item>
            {t("language")}
            <RightText>
              <Link onClick={() => i18n.changeLanguage("ja")}>{"日本語"}</Link>/
              <Link onClick={() => i18n.changeLanguage("en")}>{"English"}</Link>
            </RightText>
          </ListGroup.Item>
          <ClickListItem onClick={() => setPage(4)}>
            {t("legend")}
          </ClickListItem>
          <ClickListItem onClick={() => setPage(3)}>{t("tos")}</ClickListItem>
          <ClickListItem onClick={() => setPage(2)}>
            {t("open-source-licenses")}
          </ClickListItem>
        </ListGroup>
      );
    } else if (page === 2) {
      if (!licenseText) {
        fetch(`${process.env.PUBLIC_URL}/attribution.txt`)
          .then((r) => r.text())
          .then((text) => {
            setLisenceText(text);
          });
      }
      return <Text>{licenseText || "Loading..."}</Text>;
    } else if (page === 3) {
      return <DisclaimerText />;
    } else if (page === 4) {
      return (
        <Center>
          {legendLoading && <div>Loading...</div>}
          <MapLegend
            src={i18n.language === "ja" ? mapLegendJa : mapLegendEn}
            alt="Legend"
            onLoad={() => setLegendLoading(false)}
          />
        </Center>
      );
    }
    return;
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        props.onHide();
        setPage(0);
        setLisenceText("");
      }}
    >
      <Modal.Header className="text-center">
        <Modal.Title className="w-100">{getTitle(page)}</Modal.Title>
      </Modal.Header>
      <ScrollBody className="scrollable">{renderViews()}</ScrollBody>
      <Modal.Footer>
        {page !== 0 && (
          <Button
            className=" mr-auto"
            variant="danger"
            onClick={() => setPage(0)}
          >
            {t("back")}
          </Button>
        )}
        <Button
          onClick={() => {
            props.onHide();
            setPage(0);
            setLisenceText("");
          }}
        >
          {t("close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InfoWindow;
